import EventEmitter from 'node_modules/eventemitter3';
import Baobab from 'node_modules/baobab/dist/baobab.js';
import detectBrowser from 'node_modules/detect-browser';
import Tabbable from 'node_modules/tabbable';
import fetchJsonp from 'node_modules/fetch-jsonp';
import 'node_modules/core-js/client/shim.js';
import 'node_modules/details-element-polyfill';
import 'node_modules/focus-visible';
import 'node_modules/gsap/src/uncompressed/TweenLite.js';
import 'node_modules/gsap/src/uncompressed/plugins/ScrollToPlugin.js';
import 'node_modules/gsap/src/uncompressed/easing/EasePack.js';
import 'node_modules/html5shiv';
import 'node_modules/objectFitPolyfill';
import 'node_modules/polyfill-queryselector';
import 'node_modules/whatwg-fetch';

var _classCallCheck = (function (instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
});

var _possibleConstructorReturn = (function (self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
});

var _createClass = (function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

var _inherits = (function (subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
});

var _defineProperty = (function (obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
});

var Obj = function (_EventEmitter) {
    _inherits(Obj, _EventEmitter);

    function Obj(opts) {
        _classCallCheck(this, Obj);

        var _this = _possibleConstructorReturn(this, (Obj.__proto__ || Object.getPrototypeOf(Obj)).call(this));

        _this._passiveeventlisteners = false;

        try {
            var testOpts = Object.defineProperty({}, 'passive', {
                get: function get() {
                    _this._passiveeventlisteners = true;
                }
            });

            window.addEventListener('test', testOpts, testOpts);
            window.removeEventListener('test', testOpts, testOpts);
        } catch (err) {
            console.warn('Passive Listening Not Supported');
        }

        if (opts) _this.extend(opts);
        return _this;
    }

    _createClass(Obj, [{
        key: 'extend',
        value: function extend() {
            var _this2 = this;

            var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

            if (typeof obj !== 'object' || Array.isArray(obj)) return this;

            Object.keys(obj).forEach(function (opt) {
                _this2[opt] = obj[opt];
            });

            return this;
        }
    }]);

    return Obj;
}(EventEmitter);

var Component = function (_Obj) {
    _inherits(Component, _Obj);

    function Component() {
        var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        _classCallCheck(this, Component);

        var _this = _possibleConstructorReturn(this, (Component.__proto__ || Object.getPrototypeOf(Component)).call(this, opts));

        _this.el.setAttribute('data-rendered', true);
        _this.state = new Baobab(_this.state || {});

        _this._resizing = false;
        _this._features = {};

        _this.addFeatureTest({
            passiveeventlisteners: _this._passiveeventlisteners,
            touch: function touch() {
                return 'ontouchstart' in window && window.ontouchstart || 'DocumentTouch' in window && document instanceof window.DocumentTouch || (window.navigator.maxTouchPoints || 0) > 0 && !window.navigator.userAgent.match(/X11/);
            }
        });

        delete _this._passiveeventlisteners;

        if (_this.getBrowser('name')) _this.addFeatureTest(_defineProperty({}, _this.getBrowser('name'), true));

        if (_this.getBrowser('os')) _this.addFeatureTest(_defineProperty({}, _this.getBrowser('os'), true));

        if (!_this.el) return _possibleConstructorReturn(_this);

        _this.addFeaturesToClassName(_this.el, 'touch');

        console.groupCollapsed(_this.name || _this.constructor.name);
        console.log(_this);
        console.groupEnd();

        if (_this.el.nodeType !== 1 || !_this.el.parentElement || !(['MutationObserver'] in window)) return _possibleConstructorReturn(_this);

        var emitResize = function emitResize() {
            _this.emit('resize');

            clearTimeout(_this._onResizeDebounce);

            _this._onResizeDebounce = setTimeout(function () {
                return _this.emit('resize:debounced');
            }, 200);
        };

        window.addEventListener('resize', emitResize, false);

        var observer = new window.MutationObserver(function (mutations) {
            return mutations.filter(function (mutation) {
                return mutation.removedNodes.length;
            }).forEach(function (mutation) {
                for (var i = 0; i < mutation.removedNodes.length; i++) {
                    if (mutation.removedNodes[i] === _this.el) {
                        observer.disconnect();

                        _this.emit('destroy');
                        _this.removeAllListeners();

                        window.removeEventListener('resize', emitResize, false);
                    }
                }
            });
        });

        observer.observe(_this.el.parentElement, { childList: true });
        return _this;
    }

    _createClass(Component, [{
        key: 'addFeatureTest',
        value: function addFeatureTest(obj) {
            var _this2 = this;

            Object.keys(obj).filter(function (key) {
                return (typeof obj[key]).match(/boolean|function/);
            }).forEach(function (key) {
                _this2._features[key] = typeof obj[key] === 'boolean' ? obj[key] : obj[key]();
            });

            return this;
        }
    }, {
        key: 'addFeaturesToClassName',
        value: function addFeaturesToClassName() {
            var _this3 = this;

            var el = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.el || document.documentElement;
            var feature = arguments[1];

            Object.keys(this.getFeatures()).filter(function (key) {
                return !feature || feature && typeof feature === 'string' && key === feature;
            }).forEach(function (key) {
                return el.classList.add('' + (_this3.getFeatures()[key] ? '' : 'no-') + key);
            });

            return this;
        }
    }, {
        key: 'detectCSSProp',
        value: function detectCSSProp(str) {
            var style = document.createElement('a').style;
            style.cssText = str + ':auto';

            var camelCase = str.toLowerCase().replace(/(-\w{1})/g, function (str) {
                return str.replace(/^-/, '').toUpperCase();
            });

            return style[camelCase] === 'auto';
        }
    }, {
        key: 'getBrowser',
        value: function getBrowser(str) {
            var browser = detectBrowser.detect() || { name: null, version: null, os: null };

            browser.os = !browser.os || typeof browser.os !== 'string' ? browser.os : browser.os.replace(/\s+os$/i, '').replace(/([^a-z|0-9])/gi, '-');

            return !str || !(str in browser) ? browser : browser[str].toLowerCase();
        }
    }, {
        key: 'getFeature',
        value: function getFeature() {
            var str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

            return typeof str === 'string' && str in this.getFeatures() ? this.getFeatures()[str] : null;
        }
    }, {
        key: 'getFeatures',
        value: function getFeatures() {
            this._features = this._features || {};
            return this._features;
        }
    }, {
        key: 'generateGUID',
        value: function generateGUID() {
            var s4 = function s4() {
                return ((1 + Math.random()) * 0x10000 | 0).toString(16).substring(1);
            };

            return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + (s4() + s4() + s4());
        }
    }]);

    return Component;
}(Obj);

var CalendarComponent = function (_Component) {
    _inherits(CalendarComponent, _Component);

    _createClass(CalendarComponent, null, [{
        key: 'selector',
        value: function selector() {
            return '.calendar';
        }
    }]);

    function CalendarComponent(opts) {
        _classCallCheck(this, CalendarComponent);

        var _this = _possibleConstructorReturn(this, (CalendarComponent.__proto__ || Object.getPrototypeOf(CalendarComponent)).call(this, opts));

        _this.listen();
        return _this;
    }

    _createClass(CalendarComponent, [{
        key: 'listen',
        value: function listen() {
            var _this2 = this;

            this.getEventToggleBtns().forEach(function (el) {
                el.addEventListener('click', function (e) {
                    return _this2.onEventToggleBtnClick(el);
                });

                el.addEventListener('keydown', function (e) {
                    return _this2.onEventToggleBtnKeydown(e, el);
                });
            });
        }
    }, {
        key: 'onEventToggleBtnClick',
        value: function onEventToggleBtnClick(el) {
            var rowActive = this.el.querySelector('.calendar-list-item-toggle[aria-expanded="true"]');
            var rowClick = el.getAttribute('data-row');

            if (rowActive && rowActive !== rowClick) this.el.querySelectorAll('.calendar-list-item-toggle[data-row="' + rowActive.getAttribute('data-row') + '"]').forEach(function (elt) {
                elt.setAttribute('aria-expanded', false);
            });

            this.el.querySelectorAll('.calendar-list-item-toggle[data-row="' + rowClick + '"]').forEach(function (elt) {
                elt.setAttribute('aria-expanded', true);
            });

            this.render();
        }
    }, {
        key: 'onEventToggleBtnKeydown',
        value: function onEventToggleBtnKeydown(e, el) {
            if (e.keyCode === 32 || (e.keyIdentifier || e.key || '').match(/^(enter)$/i)) this.onEventToggleBtnClick(el);
        }
    }, {
        key: 'getEventToggleBtns',
        value: function getEventToggleBtns() {
            return Array.from(this.el.querySelectorAll('.calendar-list-item-toggle'));
        }
    }, {
        key: 'render',
        value: function render() {
            this.getEventToggleBtns().forEach(function (el) {
                if (!el.getAttribute('tabindex')) el.setAttribute('tabindex', 0);

                el.setAttribute('aria-expanded', el.getAttribute('aria-expanded') === 'true');

                var label = void 0;

                if (el.getAttribute('data-aria-label-collapsed') && el.getAttribute('aria-expanded') !== 'true') label = el.getAttribute('data-aria-label-collapsed');else if (el.getAttribute('data-aria-label-expanded') && el.getAttribute('aria-expanded') === 'true') label = el.getAttribute('data-aria-label-expanded');

                if (label) el.setAttribute('aria-label', label);
            });
        }
    }]);

    return CalendarComponent;
}(Component);

var win = window;

var raf = win.requestAnimationFrame || win.webkitRequestAnimationFrame || win.mozRequestAnimationFrame || win.msRequestAnimationFrame || function (cb) {
  return setTimeout(cb, 16);
};

var win$1 = window;

var caf = win$1.cancelAnimationFrame || win$1.mozCancelAnimationFrame || function (id) {
  clearTimeout(id);
};

function extend() {
  var obj,
      name,
      copy,
      target = arguments[0] || {},
      i = 1,
      length = arguments.length;

  for (; i < length; i++) {
    if ((obj = arguments[i]) !== null) {
      for (name in obj) {
        copy = obj[name];

        if (target === copy) {
          continue;
        } else if (copy !== undefined) {
          target[name] = copy;
        }
      }
    }
  }
  return target;
}

function checkStorageValue(value) {
  return ['true', 'false'].indexOf(value) >= 0 ? JSON.parse(value) : value;
}

function setLocalStorage(storage, key, value, access) {
  if (access) {
    try {
      storage.setItem(key, value);
    } catch (e) {}
  }
  return value;
}

function getSlideId() {
  var id = window.tnsId;
  window.tnsId = !id ? 1 : id + 1;

  return 'tns' + window.tnsId;
}

function getBody() {
  var doc = document,
      body = doc.body;

  if (!body) {
    body = doc.createElement('body');
    body.fake = true;
  }

  return body;
}

var docElement = document.documentElement;

function setFakeBody(body) {
  var docOverflow = '';
  if (body.fake) {
    docOverflow = docElement.style.overflow;
    //avoid crashing IE8, if background image is used
    body.style.background = '';
    //Safari 5.13/5.1.4 OSX stops loading if ::-webkit-scrollbar is used and scrollbars are visible
    body.style.overflow = docElement.style.overflow = 'hidden';
    docElement.appendChild(body);
  }

  return docOverflow;
}

function resetFakeBody(body, docOverflow) {
  if (body.fake) {
    body.remove();
    docElement.style.overflow = docOverflow;
    // Trigger layout so kinetic scrolling isn't disabled in iOS6+
    // eslint-disable-next-line
    docElement.offsetHeight;
  }
}

// get css-calc 

function calc() {
  var doc = document,
      body = getBody(),
      docOverflow = setFakeBody(body),
      div = doc.createElement('div'),
      result = false;

  body.appendChild(div);
  try {
    var str = '(10px * 10)',
        vals = ['calc' + str, '-moz-calc' + str, '-webkit-calc' + str],
        val;
    for (var i = 0; i < 3; i++) {
      val = vals[i];
      div.style.width = val;
      if (div.offsetWidth === 100) {
        result = val.replace(str, '');
        break;
      }
    }
  } catch (e) {}

  body.fake ? resetFakeBody(body, docOverflow) : div.remove();

  return result;
}

// get subpixel support value

function percentageLayout() {
  // check subpixel layout supporting
  var doc = document,
      body = getBody(),
      docOverflow = setFakeBody(body),
      wrapper = doc.createElement('div'),
      outer = doc.createElement('div'),
      str = '',
      count = 70,
      perPage = 3,
      supported = false;

  wrapper.className = "tns-t-subp2";
  outer.className = "tns-t-ct";

  for (var i = 0; i < count; i++) {
    str += '<div></div>';
  }

  outer.innerHTML = str;
  wrapper.appendChild(outer);
  body.appendChild(wrapper);

  supported = Math.abs(wrapper.getBoundingClientRect().left - outer.children[count - perPage].getBoundingClientRect().left) < 2;

  body.fake ? resetFakeBody(body, docOverflow) : wrapper.remove();

  return supported;
}

function mediaquerySupport() {
  var doc = document,
      body = getBody(),
      docOverflow = setFakeBody(body),
      div = doc.createElement('div'),
      style = doc.createElement('style'),
      rule = '@media all and (min-width:1px){.tns-mq-test{position:absolute}}',
      position;

  style.type = 'text/css';
  div.className = 'tns-mq-test';

  body.appendChild(style);
  body.appendChild(div);

  if (style.styleSheet) {
    style.styleSheet.cssText = rule;
  } else {
    style.appendChild(doc.createTextNode(rule));
  }

  position = window.getComputedStyle ? window.getComputedStyle(div).position : div.currentStyle['position'];

  body.fake ? resetFakeBody(body, docOverflow) : div.remove();

  return position === "absolute";
}

// create and append style sheet
function createStyleSheet(media) {
  // Create the <style> tag
  var style = document.createElement("style");
  // style.setAttribute("type", "text/css");

  // Add a media (and/or media query) here if you'd like!
  // style.setAttribute("media", "screen")
  // style.setAttribute("media", "only screen and (max-width : 1024px)")
  if (media) {
    style.setAttribute("media", media);
  }

  // WebKit hack :(
  // style.appendChild(document.createTextNode(""));

  // Add the <style> element to the page
  document.querySelector('head').appendChild(style);

  return style.sheet ? style.sheet : style.styleSheet;
}

// cross browsers addRule method
function addCSSRule(sheet, selector, rules, index) {
  // return raf(function() {
  'insertRule' in sheet ? sheet.insertRule(selector + '{' + rules + '}', index) : sheet.addRule(selector, rules, index);
  // });
}

// cross browsers addRule method
function removeCSSRule(sheet, index) {
  // return raf(function() {
  'deleteRule' in sheet ? sheet.deleteRule(index) : sheet.removeRule(index);
  // });
}

function getCssRulesLength(sheet) {
  var rule = 'insertRule' in sheet ? sheet.cssRules : sheet.rules;
  return rule.length;
}

function toDegree(y, x) {
  return Math.atan2(y, x) * (180 / Math.PI);
}

function getTouchDirection(angle, range) {
  var direction = false,
      gap = Math.abs(90 - Math.abs(angle));

  if (gap >= 90 - range) {
    direction = 'horizontal';
  } else if (gap <= range) {
    direction = 'vertical';
  }

  return direction;
}

// https://toddmotto.com/ditch-the-array-foreach-call-nodelist-hack/
function forEach(arr, callback, scope) {
  for (var i = 0, l = arr.length; i < l; i++) {
    callback.call(scope, arr[i], i);
  }
}

var classListSupport = 'classList' in document.createElement('_');

var hasClass = classListSupport ? function (el, str) {
    return el.classList.contains(str);
} : function (el, str) {
    return el.className.indexOf(str) >= 0;
};

var addClass = classListSupport ? function (el, str) {
  if (!hasClass(el, str)) {
    el.classList.add(str);
  }
} : function (el, str) {
  if (!hasClass(el, str)) {
    el.className += ' ' + str;
  }
};

var removeClass = classListSupport ? function (el, str) {
  if (hasClass(el, str)) {
    el.classList.remove(str);
  }
} : function (el, str) {
  if (hasClass(el, str)) {
    el.className = el.className.replace(str, '');
  }
};

function hasAttr(el, attr) {
  return el.hasAttribute(attr);
}

function getAttr(el, attr) {
  return el.getAttribute(attr);
}

function isNodeList(el) {
  // Only NodeList has the "item()" function
  return typeof el.item !== "undefined";
}

function setAttrs(els, attrs) {
  els = isNodeList(els) || els instanceof Array ? els : [els];
  if (Object.prototype.toString.call(attrs) !== '[object Object]') {
    return;
  }

  for (var i = els.length; i--;) {
    for (var key in attrs) {
      els[i].setAttribute(key, attrs[key]);
    }
  }
}

function removeAttrs(els, attrs) {
  els = isNodeList(els) || els instanceof Array ? els : [els];
  attrs = attrs instanceof Array ? attrs : [attrs];

  var attrLength = attrs.length;
  for (var i = els.length; i--;) {
    for (var j = attrLength; j--;) {
      els[i].removeAttribute(attrs[j]);
    }
  }
}

function arrayFromNodeList(nl) {
  var arr = [];
  for (var i = 0, l = nl.length; i < l; i++) {
    arr.push(nl[i]);
  }
  return arr;
}

function hideElement(el, forceHide) {
  if (el.style.display !== 'none') {
    el.style.display = 'none';
  }
}

function showElement(el, forceHide) {
  if (el.style.display === 'none') {
    el.style.display = '';
  }
}

function isVisible(el) {
  return window.getComputedStyle(el).display !== 'none';
}

function whichProperty(props) {
  if (typeof props === 'string') {
    var arr = [props],
        Props = props.charAt(0).toUpperCase() + props.substr(1),
        prefixes = ['Webkit', 'Moz', 'ms', 'O'];

    prefixes.forEach(function (prefix) {
      if (prefix !== 'ms' || props === 'transform') {
        arr.push(prefix + Props);
      }
    });

    props = arr;
  }

  var el = document.createElement('fakeelement'),
      len = props.length;
  for (var i = 0; i < props.length; i++) {
    var prop = props[i];
    if (el.style[prop] !== undefined) {
      return prop;
    }
  }

  return false; // explicit for ie9-
}

function has3DTransforms(tf) {
    if (!tf) {
        return false;
    }
    if (!window.getComputedStyle) {
        return false;
    }

    var doc = document,
        body = getBody(),
        docOverflow = setFakeBody(body),
        el = doc.createElement('p'),
        has3d,
        cssTF = tf.length > 9 ? '-' + tf.slice(0, -9).toLowerCase() + '-' : '';

    cssTF += 'transform';

    // Add it to the body to get the computed style
    body.insertBefore(el, null);

    el.style[tf] = 'translate3d(1px,1px,1px)';
    has3d = window.getComputedStyle(el).getPropertyValue(cssTF);

    body.fake ? resetFakeBody(body, docOverflow) : el.remove();

    return has3d !== undefined && has3d.length > 0 && has3d !== "none";
}

// get transitionend, animationend based on transitionDuration
// @propin: string
// @propOut: string, first-letter uppercase
// Usage: getEndProperty('WebkitTransitionDuration', 'Transition') => webkitTransitionEnd
function getEndProperty(propIn, propOut) {
  var endProp = false;
  if (/^Webkit/.test(propIn)) {
    endProp = 'webkit' + propOut + 'End';
  } else if (/^O/.test(propIn)) {
    endProp = 'o' + propOut + 'End';
  } else if (propIn) {
    endProp = propOut.toLowerCase() + 'end';
  }
  return endProp;
}

// Test via a getter in the options object to see if the passive property is accessed
var supportsPassive = false;
try {
  var opts = Object.defineProperty({}, 'passive', {
    get: function get() {
      supportsPassive = true;
    }
  });
  window.addEventListener("test", null, opts);
} catch (e) {}
var passiveOption = supportsPassive ? { passive: true } : false;

function addEvents(el, obj, preventScrolling) {
  for (var prop in obj) {
    var option = ['touchstart', 'touchmove'].indexOf(prop) >= 0 && !preventScrolling ? passiveOption : false;
    el.addEventListener(prop, obj[prop], option);
  }
}

function removeEvents(el, obj) {
  for (var prop in obj) {
    var option = ['touchstart', 'touchmove'].indexOf(prop) >= 0 ? passiveOption : false;
    el.removeEventListener(prop, obj[prop], option);
  }
}

function Events() {
  return {
    topics: {},
    on: function on(eventName, fn) {
      this.topics[eventName] = this.topics[eventName] || [];
      this.topics[eventName].push(fn);
    },
    off: function off(eventName, fn) {
      if (this.topics[eventName]) {
        for (var i = 0; i < this.topics[eventName].length; i++) {
          if (this.topics[eventName][i] === fn) {
            this.topics[eventName].splice(i, 1);
            break;
          }
        }
      }
    },
    emit: function emit(eventName, data) {
      data.type = eventName;
      if (this.topics[eventName]) {
        this.topics[eventName].forEach(function (fn) {
          fn(data, eventName);
        });
      }
    }
  };
}

function jsTransform(element, attr, prefix, postfix, to, duration, callback) {
  var tick = Math.min(duration, 10),
      unit = to.indexOf('%') >= 0 ? '%' : 'px',
      to = to.replace(unit, ''),
      from = Number(element.style[attr].replace(prefix, '').replace(postfix, '').replace(unit, '')),
      positionTick = (to - from) / duration * tick;

  setTimeout(moveElement, tick);
  function moveElement() {
    duration -= tick;
    from += positionTick;
    element.style[attr] = prefix + from + unit + postfix;
    if (duration > 0) {
      setTimeout(moveElement, tick);
    } else {
      callback();
    }
  }
}

// polyfill
if (!Object.keys) {
  Object.keys = function (object) {
    var keys = [];
    for (var name in object) {
      if (Object.prototype.hasOwnProperty.call(object, name)) {
        keys.push(name);
      }
    }
    return keys;
  };
}
if (!("remove" in Element.prototype)) {
  Element.prototype.remove = function () {
    if (this.parentNode) {
      this.parentNode.removeChild(this);
    }
  };
}

var tns = function tns(options) {
  options = extend({
    container: '.slider',
    mode: 'carousel',
    axis: 'horizontal',
    items: 1,
    gutter: 0,
    edgePadding: 0,
    fixedWidth: false,
    autoWidth: false,
    viewportMax: false,
    slideBy: 1,
    center: false,
    controls: true,
    controlsPosition: 'top',
    controlsText: ['prev', 'next'],
    controlsContainer: false,
    prevButton: false,
    nextButton: false,
    nav: true,
    navPosition: 'top',
    navContainer: false,
    navAsThumbnails: false,
    arrowKeys: false,
    speed: 300,
    autoplay: false,
    autoplayPosition: 'top',
    autoplayTimeout: 5000,
    autoplayDirection: 'forward',
    autoplayText: ['start', 'stop'],
    autoplayHoverPause: false,
    autoplayButton: false,
    autoplayButtonOutput: true,
    autoplayResetOnVisibility: true,
    animateIn: 'tns-fadeIn',
    animateOut: 'tns-fadeOut',
    animateNormal: 'tns-normal',
    animateDelay: false,
    loop: true,
    rewind: false,
    autoHeight: false,
    responsive: false,
    lazyload: false,
    lazyloadSelector: '.tns-lazy-img',
    touch: true,
    mouseDrag: false,
    swipeAngle: 15,
    nested: false,
    preventActionWhenRunning: false,
    preventScrollOnTouch: false,
    freezable: true,
    onInit: false,
    useLocalStorage: true
  }, options || {});

  var doc = document,
      win = window,
      KEYS = {
    ENTER: 13,
    SPACE: 32,
    LEFT: 37,
    RIGHT: 39
  },
      tnsStorage = {},
      localStorageAccess = options.useLocalStorage;

  if (localStorageAccess) {
    // check browser version and local storage access
    var browserInfo = navigator.userAgent;
    var uid = new Date();

    try {
      tnsStorage = win.localStorage;
      if (tnsStorage) {
        tnsStorage.setItem(uid, uid);
        localStorageAccess = tnsStorage.getItem(uid) == uid;
        tnsStorage.removeItem(uid);
      } else {
        localStorageAccess = false;
      }
      if (!localStorageAccess) {
        tnsStorage = {};
      }
    } catch (e) {
      localStorageAccess = false;
    }

    if (localStorageAccess) {
      // remove storage when browser version changes
      if (tnsStorage['tnsApp'] && tnsStorage['tnsApp'] !== browserInfo) {
        ['tC', 'tPL', 'tMQ', 'tTf', 't3D', 'tTDu', 'tTDe', 'tADu', 'tADe', 'tTE', 'tAE'].forEach(function (item) {
          tnsStorage.removeItem(item);
        });
      }
      // update browserInfo
      localStorage['tnsApp'] = browserInfo;
    }
  }

  var CALC = tnsStorage['tC'] ? checkStorageValue(tnsStorage['tC']) : setLocalStorage(tnsStorage, 'tC', calc(), localStorageAccess),
      PERCENTAGELAYOUT = tnsStorage['tPL'] ? checkStorageValue(tnsStorage['tPL']) : setLocalStorage(tnsStorage, 'tPL', percentageLayout(), localStorageAccess),
      CSSMQ = tnsStorage['tMQ'] ? checkStorageValue(tnsStorage['tMQ']) : setLocalStorage(tnsStorage, 'tMQ', mediaquerySupport(), localStorageAccess),
      TRANSFORM = tnsStorage['tTf'] ? checkStorageValue(tnsStorage['tTf']) : setLocalStorage(tnsStorage, 'tTf', whichProperty('transform'), localStorageAccess),
      HAS3DTRANSFORMS = tnsStorage['t3D'] ? checkStorageValue(tnsStorage['t3D']) : setLocalStorage(tnsStorage, 't3D', has3DTransforms(TRANSFORM), localStorageAccess),
      TRANSITIONDURATION = tnsStorage['tTDu'] ? checkStorageValue(tnsStorage['tTDu']) : setLocalStorage(tnsStorage, 'tTDu', whichProperty('transitionDuration'), localStorageAccess),
      TRANSITIONDELAY = tnsStorage['tTDe'] ? checkStorageValue(tnsStorage['tTDe']) : setLocalStorage(tnsStorage, 'tTDe', whichProperty('transitionDelay'), localStorageAccess),
      ANIMATIONDURATION = tnsStorage['tADu'] ? checkStorageValue(tnsStorage['tADu']) : setLocalStorage(tnsStorage, 'tADu', whichProperty('animationDuration'), localStorageAccess),
      ANIMATIONDELAY = tnsStorage['tADe'] ? checkStorageValue(tnsStorage['tADe']) : setLocalStorage(tnsStorage, 'tADe', whichProperty('animationDelay'), localStorageAccess),
      TRANSITIONEND = tnsStorage['tTE'] ? checkStorageValue(tnsStorage['tTE']) : setLocalStorage(tnsStorage, 'tTE', getEndProperty(TRANSITIONDURATION, 'Transition'), localStorageAccess),
      ANIMATIONEND = tnsStorage['tAE'] ? checkStorageValue(tnsStorage['tAE']) : setLocalStorage(tnsStorage, 'tAE', getEndProperty(ANIMATIONDURATION, 'Animation'), localStorageAccess);

  // get element nodes from selectors
  var supportConsoleWarn = win.console && typeof win.console.warn === "function",
      tnsList = ['container', 'controlsContainer', 'prevButton', 'nextButton', 'navContainer', 'autoplayButton'],
      optionsElements = {};

  tnsList.forEach(function (item) {
    if (typeof options[item] === 'string') {
      var str = options[item],
          el = doc.querySelector(str);
      optionsElements[item] = str;

      if (el && el.nodeName) {
        options[item] = el;
      } else {
        if (supportConsoleWarn) {
          console.warn('Can\'t find', options[item]);
        }
        return;
      }
    }
  });

  // make sure at least 1 slide
  if (options.container.children.length < 1) {
    if (supportConsoleWarn) {
      console.warn('No slides found in', options.container);
    }
    return;
  }

  // update options
  var responsive = options.responsive,
      nested = options.nested,
      carousel = options.mode === 'carousel' ? true : false;

  if (responsive) {
    // apply responsive[0] to options and remove it
    if (0 in responsive) {
      options = extend(options, responsive[0]);
      delete responsive[0];
    }

    var responsiveTem = {};
    for (var key in responsive) {
      var val = responsive[key];
      // update responsive
      // from: 300: 2
      // to: 
      //   300: { 
      //     items: 2 
      //   } 
      val = typeof val === 'number' ? { items: val } : val;
      responsiveTem[key] = val;
    }
    responsive = responsiveTem;
    responsiveTem = null;
  }

  // update options
  function updateOptions(obj) {
    for (var key in obj) {
      if (!carousel) {
        if (key === 'slideBy') {
          obj[key] = 'page';
        }
        if (key === 'edgePadding') {
          obj[key] = false;
        }
        if (key === 'autoHeight') {
          obj[key] = false;
        }
      }

      // update responsive options
      if (key === 'responsive') {
        updateOptions(obj[key]);
      }
    }
  }
  if (!carousel) {
    updateOptions(options);
  }

  // === define and set variables ===
  if (!carousel) {
    options.axis = 'horizontal';
    options.slideBy = 'page';
    options.edgePadding = false;

    var animateIn = options.animateIn,
        animateOut = options.animateOut,
        animateDelay = options.animateDelay,
        animateNormal = options.animateNormal;
  }

  var horizontal = options.axis === 'horizontal' ? true : false,
      outerWrapper = doc.createElement('div'),
      innerWrapper = doc.createElement('div'),
      middleWrapper,
      container = options.container,
      containerParent = container.parentNode,
      containerHTML = container.outerHTML,
      slideItems = container.children,
      slideCount = slideItems.length,
      breakpointZone,
      windowWidth = getWindowWidth(),
      isOn = false;
  if (responsive) {
    setBreakpointZone();
  }
  if (carousel) {
    container.className += ' tns-vpfix';
  }

  // fixedWidth: viewport > rightBoundary > indexMax
  var autoWidth = options.autoWidth,
      fixedWidth = getOption('fixedWidth'),
      edgePadding = getOption('edgePadding'),
      gutter = getOption('gutter'),
      viewport = getViewportWidth(),
      center = getOption('center'),
      items = !autoWidth ? Math.floor(getOption('items')) : 1,
      slideBy = getOption('slideBy'),
      viewportMax = options.viewportMax || options.fixedWidthViewportWidth,
      arrowKeys = getOption('arrowKeys'),
      speed = getOption('speed'),
      rewind = options.rewind,
      loop = rewind ? false : options.loop,
      autoHeight = getOption('autoHeight'),
      controls = getOption('controls'),
      controlsText = getOption('controlsText'),
      nav = getOption('nav'),
      touch = getOption('touch'),
      mouseDrag = getOption('mouseDrag'),
      autoplay = getOption('autoplay'),
      autoplayTimeout = getOption('autoplayTimeout'),
      autoplayText = getOption('autoplayText'),
      autoplayHoverPause = getOption('autoplayHoverPause'),
      autoplayResetOnVisibility = getOption('autoplayResetOnVisibility'),
      sheet = createStyleSheet(),
      lazyload = options.lazyload,
      lazyloadSelector = options.lazyloadSelector,
      slidePositions,
      // collection of slide positions
  slideItemsOut = [],
      cloneCount = loop ? getCloneCountForLoop() : 0,
      slideCountNew = !carousel ? slideCount + cloneCount : slideCount + cloneCount * 2,
      hasRightDeadZone = (fixedWidth || autoWidth) && !loop ? true : false,
      rightBoundary = fixedWidth ? getRightBoundary() : null,
      updateIndexBeforeTransform = !carousel || !loop ? true : false,

  // transform
  transformAttr = horizontal ? 'left' : 'top',
      transformPrefix = '',
      transformPostfix = '',

  // index
  getIndexMax = function () {
    if (fixedWidth) {
      return function () {
        return center && !loop ? slideCount - 1 : Math.ceil(-rightBoundary / (fixedWidth + gutter));
      };
    } else if (autoWidth) {
      return function () {
        for (var i = slideCountNew; i--;) {
          if (slidePositions[i] > -rightBoundary) {
            return i;
          }
        }
      };
    } else {
      return function () {
        if (center && carousel && !loop) {
          return slideCount - 1;
        } else {
          return loop || carousel ? Math.max(0, slideCountNew - Math.ceil(items)) : slideCountNew - 1;
        }
      };
    }
  }(),
      index = getStartIndex(getOption('startIndex')),
      indexCached = index,
      displayIndex = getCurrentSlide(),
      indexMin = 0,
      indexMax = !autoWidth ? getIndexMax() : null,

  // resize
  resizeTimer,
      preventActionWhenRunning = options.preventActionWhenRunning,
      swipeAngle = options.swipeAngle,
      moveDirectionExpected = swipeAngle ? '?' : true,
      running = false,
      onInit = options.onInit,
      events = new Events(),

  // id, class
  newContainerClasses = ' tns-slider tns-' + options.mode,
      slideId = container.id || getSlideId(),
      disable = getOption('disable'),
      disabled = false,
      freezable = options.freezable,
      freeze = freezable && !autoWidth ? getFreeze() : false,
      frozen = false,
      controlsEvents = {
    'click': onControlsClick,
    'keydown': onControlsKeydown
  },
      navEvents = {
    'click': onNavClick,
    'keydown': onNavKeydown
  },
      hoverEvents = {
    'mouseover': mouseoverPause,
    'mouseout': mouseoutRestart
  },
      visibilityEvent = { 'visibilitychange': onVisibilityChange },
      docmentKeydownEvent = { 'keydown': onDocumentKeydown },
      touchEvents = {
    'touchstart': onPanStart,
    'touchmove': onPanMove,
    'touchend': onPanEnd,
    'touchcancel': onPanEnd
  },
      dragEvents = {
    'mousedown': onPanStart,
    'mousemove': onPanMove,
    'mouseup': onPanEnd,
    'mouseleave': onPanEnd
  },
      hasControls = hasOption('controls'),
      hasNav = hasOption('nav'),
      navAsThumbnails = autoWidth ? true : options.navAsThumbnails,
      hasAutoplay = hasOption('autoplay'),
      hasTouch = hasOption('touch'),
      hasMouseDrag = hasOption('mouseDrag'),
      slideActiveClass = 'tns-slide-active',
      imgCompleteClass = 'tns-complete',
      imgEvents = {
    'load': onImgLoaded,
    'error': onImgFailed
  },
      imgsComplete,
      liveregionCurrent,
      preventScroll = options.preventScrollOnTouch === 'force' ? true : false;

  // controls
  if (hasControls) {
    var controlsContainer = options.controlsContainer,
        controlsContainerHTML = options.controlsContainer ? options.controlsContainer.outerHTML : '',
        prevButton = options.prevButton,
        nextButton = options.nextButton,
        prevButtonHTML = options.prevButton ? options.prevButton.outerHTML : '',
        nextButtonHTML = options.nextButton ? options.nextButton.outerHTML : '',
        prevIsButton,
        nextIsButton;
  }

  // nav
  if (hasNav) {
    var navContainer = options.navContainer,
        navContainerHTML = options.navContainer ? options.navContainer.outerHTML : '',
        navItems,
        pages = autoWidth ? slideCount : getPages(),
        pagesCached = 0,
        navClicked = -1,
        navCurrentIndex = getCurrentNavIndex(),
        navCurrentIndexCached = navCurrentIndex,
        navActiveClass = 'tns-nav-active',
        navStr = 'Carousel Page ',
        navStrCurrent = ' (Current Slide)';
  }

  // autoplay
  if (hasAutoplay) {
    var autoplayDirection = options.autoplayDirection === 'forward' ? 1 : -1,
        autoplayButton = options.autoplayButton,
        autoplayButtonHTML = options.autoplayButton ? options.autoplayButton.outerHTML : '',
        autoplayHtmlStrings = ['<span class=\'tns-visually-hidden\'>', ' animation</span>'],
        autoplayTimer,
        animating,
        autoplayHoverPaused,
        autoplayUserPaused,
        autoplayVisibilityPaused;
  }

  if (hasTouch || hasMouseDrag) {
    var initPosition = {},
        lastPosition = {},
        translateInit,
        disX,
        disY,
        panStart = false,
        rafIndex,
        getDist = horizontal ? function (a, b) {
      return a.x - b.x;
    } : function (a, b) {
      return a.y - b.y;
    };
  }

  // disable slider when slidecount <= items
  if (!autoWidth) {
    resetVariblesWhenDisable(disable || freeze);
  }

  if (TRANSFORM) {
    transformAttr = TRANSFORM;
    transformPrefix = 'translate';

    if (HAS3DTRANSFORMS) {
      transformPrefix += horizontal ? '3d(' : '3d(0px, ';
      transformPostfix = horizontal ? ', 0px, 0px)' : ', 0px)';
    } else {
      transformPrefix += horizontal ? 'X(' : 'Y(';
      transformPostfix = ')';
    }
  }

  if (carousel) {
    container.className = container.className.replace('tns-vpfix', '');
  }
  initStructure();
  initSheet();
  initSliderTransform();

  // === COMMON FUNCTIONS === //
  function resetVariblesWhenDisable(condition) {
    if (condition) {
      controls = nav = touch = mouseDrag = arrowKeys = autoplay = autoplayHoverPause = autoplayResetOnVisibility = false;
    }
  }

  function getCurrentSlide() {
    var tem = carousel ? index - cloneCount : index;
    while (tem < 0) {
      tem += slideCount;
    }
    return tem % slideCount + 1;
  }

  function getStartIndex(ind) {
    ind = ind ? Math.max(0, Math.min(loop ? slideCount - 1 : slideCount - items, ind)) : 0;
    return carousel ? ind + cloneCount : ind;
  }

  function getAbsIndex(i) {
    if (i == null) {
      i = index;
    }

    if (carousel) {
      i -= cloneCount;
    }
    while (i < 0) {
      i += slideCount;
    }

    return Math.floor(i % slideCount);
  }

  function getCurrentNavIndex() {
    var absIndex = getAbsIndex(),
        result;

    result = navAsThumbnails ? absIndex : fixedWidth || autoWidth ? Math.ceil((absIndex + 1) * pages / slideCount - 1) : Math.floor(absIndex / items);

    // set active nav to the last one when reaches the right edge
    if (!loop && carousel && index === indexMax) {
      result = pages - 1;
    }

    return result;
  }

  function getItemsMax() {
    // fixedWidth or autoWidth while viewportMax is not available
    if (autoWidth || fixedWidth && !viewportMax) {
      return slideCount - 1;
      // most cases
    } else {
      var str = fixedWidth ? 'fixedWidth' : 'items',
          arr = [];

      if (fixedWidth || options[str] < slideCount) {
        arr.push(options[str]);
      }

      if (responsive) {
        for (var bp in responsive) {
          var tem = responsive[bp][str];
          if (tem && (fixedWidth || tem < slideCount)) {
            arr.push(tem);
          }
        }
      }

      if (!arr.length) {
        arr.push(0);
      }

      return Math.ceil(fixedWidth ? viewportMax / Math.min.apply(null, arr) : Math.max.apply(null, arr));
    }
  }

  function getCloneCountForLoop() {
    var itemsMax = getItemsMax(),
        result = carousel ? Math.ceil((itemsMax * 5 - slideCount) / 2) : itemsMax * 4 - slideCount;
    result = Math.max(itemsMax, result);

    return hasOption('edgePadding') ? result + 1 : result;
  }

  function getWindowWidth() {
    return win.innerWidth || doc.documentElement.clientWidth || doc.body.clientWidth;
  }

  function getInsertPosition(pos) {
    return pos === 'top' ? 'afterbegin' : 'beforeend';
  }

  function getClientWidth(el) {
    var div = doc.createElement('div'),
        rect,
        width;
    el.appendChild(div);
    rect = div.getBoundingClientRect();
    width = rect.right - rect.left;
    div.remove();
    return width || getClientWidth(el.parentNode);
  }

  function getViewportWidth() {
    var gap = edgePadding ? edgePadding * 2 - gutter : 0;
    return getClientWidth(containerParent) - gap;
  }

  function hasOption(item) {
    if (options[item]) {
      return true;
    } else {
      if (responsive) {
        for (var bp in responsive) {
          if (responsive[bp][item]) {
            return true;
          }
        }
      }
      return false;
    }
  }

  // get option:
  // fixed width: viewport, fixedWidth, gutter => items
  // others: window width => all variables
  // all: items => slideBy
  function getOption(item, ww) {
    if (ww == null) {
      ww = windowWidth;
    }

    if (item === 'items' && fixedWidth) {
      return Math.floor((viewport + gutter) / (fixedWidth + gutter)) || 1;
    } else {
      var result = options[item];

      if (responsive) {
        for (var bp in responsive) {
          // bp: convert string to number
          if (ww >= parseInt(bp)) {
            if (item in responsive[bp]) {
              result = responsive[bp][item];
            }
          }
        }
      }

      if (item === 'slideBy' && result === 'page') {
        result = getOption('items');
      }
      if (!carousel && (item === 'slideBy' || item === 'items')) {
        result = Math.floor(result);
      }

      return result;
    }
  }

  function getSlideMarginLeft(i) {
    return CALC ? CALC + '(' + i * 100 + '% / ' + slideCountNew + ')' : i * 100 / slideCountNew + '%';
  }

  function getInnerWrapperStyles(edgePaddingTem, gutterTem, fixedWidthTem, speedTem, autoHeightBP) {
    var str = '';

    if (edgePaddingTem !== undefined) {
      var gap = edgePaddingTem;
      if (gutterTem) {
        gap -= gutterTem;
      }
      str = horizontal ? 'margin: 0 ' + gap + 'px 0 ' + edgePaddingTem + 'px;' : 'margin: ' + edgePaddingTem + 'px 0 ' + gap + 'px 0;';
    } else if (gutterTem && !fixedWidthTem) {
      var gutterTemUnit = '-' + gutterTem + 'px',
          dir = horizontal ? gutterTemUnit + ' 0 0' : '0 ' + gutterTemUnit + ' 0';
      str = 'margin: 0 ' + dir + ';';
    }

    if (!carousel && autoHeightBP && TRANSITIONDURATION && speedTem) {
      str += getTransitionDurationStyle(speedTem);
    }
    return str;
  }

  function getContainerWidth(fixedWidthTem, gutterTem, itemsTem) {
    if (fixedWidthTem) {
      return (fixedWidthTem + gutterTem) * slideCountNew + 'px';
    } else {
      return CALC ? CALC + '(' + slideCountNew * 100 + '% / ' + itemsTem + ')' : slideCountNew * 100 / itemsTem + '%';
    }
  }

  function getSlideWidthStyle(fixedWidthTem, gutterTem, itemsTem) {
    var width;

    if (fixedWidthTem) {
      width = fixedWidthTem + gutterTem + 'px';
    } else {
      if (!carousel) {
        itemsTem = Math.floor(itemsTem);
      }
      var dividend = carousel ? slideCountNew : itemsTem;
      width = CALC ? CALC + '(100% / ' + dividend + ')' : 100 / dividend + '%';
    }

    width = 'width:' + width;

    // inner slider: overwrite outer slider styles
    return nested !== 'inner' ? width + ';' : width + ' !important;';
  }

  function getSlideGutterStyle(gutterTem) {
    var str = '';

    // gutter maybe interger || 0
    // so can't use 'if (gutter)'
    if (gutterTem !== false) {
      var prop = horizontal ? 'padding-' : 'margin-',
          dir = horizontal ? 'right' : 'bottom';
      str = prop + dir + ': ' + gutterTem + 'px;';
    }

    return str;
  }

  function getCSSPrefix(name, num) {
    var prefix = name.substring(0, name.length - num).toLowerCase();
    if (prefix) {
      prefix = '-' + prefix + '-';
    }

    return prefix;
  }

  function getTransitionDurationStyle(speed) {
    return getCSSPrefix(TRANSITIONDURATION, 18) + 'transition-duration:' + speed / 1000 + 's;';
  }

  function getAnimationDurationStyle(speed) {
    return getCSSPrefix(ANIMATIONDURATION, 17) + 'animation-duration:' + speed / 1000 + 's;';
  }

  function initStructure() {
    var classOuter = 'tns-outer',
        classInner = 'tns-inner',
        hasGutter = hasOption('gutter');

    outerWrapper.className = classOuter;
    innerWrapper.className = classInner;
    outerWrapper.id = slideId + '-ow';
    innerWrapper.id = slideId + '-iw';

    // set container properties
    if (container.id === '') {
      container.id = slideId;
    }
    newContainerClasses += PERCENTAGELAYOUT || autoWidth ? ' tns-subpixel' : ' tns-no-subpixel';
    newContainerClasses += CALC ? ' tns-calc' : ' tns-no-calc';
    if (autoWidth) {
      newContainerClasses += ' tns-autowidth';
    }
    newContainerClasses += ' tns-' + options.axis;
    container.className += newContainerClasses;

    // add constrain layer for carousel
    if (carousel) {
      middleWrapper = doc.createElement('div');
      middleWrapper.id = slideId + '-mw';
      middleWrapper.className = 'tns-ovh';

      outerWrapper.appendChild(middleWrapper);
      middleWrapper.appendChild(innerWrapper);
    } else {
      outerWrapper.appendChild(innerWrapper);
    }

    if (autoHeight) {
      var wp = middleWrapper ? middleWrapper : innerWrapper;
      wp.className += ' tns-ah';
    }

    containerParent.insertBefore(outerWrapper, container);
    innerWrapper.appendChild(container);

    // add id, class, aria attributes 
    // before clone slides
    forEach(slideItems, function (item, i) {
      addClass(item, 'tns-item');
      if (!item.id) {
        item.id = slideId + '-item' + i;
      }
      if (!carousel && animateNormal) {
        addClass(item, animateNormal);
      }
      setAttrs(item, {
        'aria-hidden': 'true',
        'tabindex': '-1'
      });
    });

    // ## clone slides
    // carousel: n + slides + n
    // gallery:      slides + n
    if (cloneCount) {
      var fragmentBefore = doc.createDocumentFragment(),
          fragmentAfter = doc.createDocumentFragment();

      for (var j = cloneCount; j--;) {
        var num = j % slideCount,
            cloneFirst = slideItems[num].cloneNode(true);
        removeAttrs(cloneFirst, 'id');
        fragmentAfter.insertBefore(cloneFirst, fragmentAfter.firstChild);

        if (carousel) {
          var cloneLast = slideItems[slideCount - 1 - num].cloneNode(true);
          removeAttrs(cloneLast, 'id');
          fragmentBefore.appendChild(cloneLast);
        }
      }

      container.insertBefore(fragmentBefore, container.firstChild);
      container.appendChild(fragmentAfter);
      slideItems = container.children;
    }
  }

  function initSliderTransform() {
    // ## images loaded/failed
    if (hasOption('autoHeight') || autoWidth || !horizontal) {
      var imgs = container.querySelectorAll('img');

      // add complete class if all images are loaded/failed
      forEach(imgs, function (img) {
        var src = img.src;

        if (src && src.indexOf('data:image') < 0) {
          addEvents(img, imgEvents);
          img.src = '';
          img.src = src;
          addClass(img, 'loading');
        } else if (!lazyload) {
          imgLoaded(img);
        }
      });

      // All imgs are completed
      raf(function () {
        imgsLoadedCheck(arrayFromNodeList(imgs), function () {
          imgsComplete = true;
        });
      });

      // Check imgs in window only for auto height
      if (!autoWidth && horizontal) {
        imgs = getImageArray(index, Math.min(index + items - 1, slideCountNew - 1));
      }

      lazyload ? initSliderTransformStyleCheck() : raf(function () {
        imgsLoadedCheck(arrayFromNodeList(imgs), initSliderTransformStyleCheck);
      });
    } else {
      // set container transform property
      if (carousel) {
        doContainerTransformSilent();
      }

      // update slider tools and events
      initTools();
      initEvents();
    }
  }

  function initSliderTransformStyleCheck() {
    if (autoWidth) {
      // check styles application
      var num = loop ? index : slideCount - 1;
      (function stylesApplicationCheck() {
        slideItems[num - 1].getBoundingClientRect().right.toFixed(2) === slideItems[num].getBoundingClientRect().left.toFixed(2) ? initSliderTransformCore() : setTimeout(function () {
          stylesApplicationCheck();
        }, 16);
      })();
    } else {
      initSliderTransformCore();
    }
  }

  function initSliderTransformCore() {
    // run Fn()s which are rely on image loading
    if (!horizontal || autoWidth) {
      setSlidePositions();

      if (autoWidth) {
        rightBoundary = getRightBoundary();
        if (freezable) {
          freeze = getFreeze();
        }
        indexMax = getIndexMax(); // <= slidePositions, rightBoundary <=
        resetVariblesWhenDisable(disable || freeze);
      } else {
        updateContentWrapperHeight();
      }
    }

    // set container transform property
    if (carousel) {
      doContainerTransformSilent();
    }

    // update slider tools and events
    initTools();
    initEvents();
  }

  function initSheet() {
    // gallery:
    // set animation classes and left value for gallery slider
    if (!carousel) {
      for (var i = index, l = index + Math.min(slideCount, items); i < l; i++) {
        var item = slideItems[i];
        item.style.left = (i - index) * 100 / items + '%';
        addClass(item, animateIn);
        removeClass(item, animateNormal);
      }
    }

    // #### LAYOUT

    // ## INLINE-BLOCK VS FLOAT

    // ## PercentageLayout:
    // slides: inline-block
    // remove blank space between slides by set font-size: 0

    // ## Non PercentageLayout:
    // slides: float
    //         margin-right: -100%
    //         margin-left: ~

    // Resource: https://docs.google.com/spreadsheets/d/147up245wwTXeQYve3BRSAD4oVcvQmuGsFteJOeA5xNQ/edit?usp=sharing
    if (horizontal) {
      if (PERCENTAGELAYOUT || autoWidth) {
        addCSSRule(sheet, '#' + slideId + ' > .tns-item', 'font-size:' + win.getComputedStyle(slideItems[0]).fontSize + ';', getCssRulesLength(sheet));
        addCSSRule(sheet, '#' + slideId, 'font-size:0;', getCssRulesLength(sheet));
      } else if (carousel) {
        forEach(slideItems, function (slide, i) {
          slide.style.marginLeft = getSlideMarginLeft(i);
        });
      }
    }

    // ## BASIC STYLES
    if (CSSMQ) {
      // middle wrapper style
      if (TRANSITIONDURATION) {
        var str = middleWrapper && options.autoHeight ? getTransitionDurationStyle(options.speed) : '';
        addCSSRule(sheet, '#' + slideId + '-mw', str, getCssRulesLength(sheet));
      }

      // inner wrapper styles
      str = getInnerWrapperStyles(options.edgePadding, options.gutter, options.fixedWidth, options.speed, options.autoHeight);
      addCSSRule(sheet, '#' + slideId + '-iw', str, getCssRulesLength(sheet));

      // container styles
      if (carousel) {
        str = horizontal && !autoWidth ? 'width:' + getContainerWidth(options.fixedWidth, options.gutter, options.items) + ';' : '';
        if (TRANSITIONDURATION) {
          str += getTransitionDurationStyle(speed);
        }
        addCSSRule(sheet, '#' + slideId, str, getCssRulesLength(sheet));
      }

      // slide styles
      str = horizontal && !autoWidth ? getSlideWidthStyle(options.fixedWidth, options.gutter, options.items) : '';
      if (options.gutter) {
        str += getSlideGutterStyle(options.gutter);
      }
      // set gallery items transition-duration
      if (!carousel) {
        if (TRANSITIONDURATION) {
          str += getTransitionDurationStyle(speed);
        }
        if (ANIMATIONDURATION) {
          str += getAnimationDurationStyle(speed);
        }
      }
      if (str) {
        addCSSRule(sheet, '#' + slideId + ' > .tns-item', str, getCssRulesLength(sheet));
      }

      // non CSS mediaqueries: IE8
      // ## update inner wrapper, container, slides if needed
      // set inline styles for inner wrapper & container
      // insert stylesheet (one line) for slides only (since slides are many)
    } else {
      // middle wrapper styles
      update_carousel_transition_duration();

      // inner wrapper styles
      innerWrapper.style.cssText = getInnerWrapperStyles(edgePadding, gutter, fixedWidth, autoHeight);

      // container styles
      if (carousel && horizontal && !autoWidth) {
        container.style.width = getContainerWidth(fixedWidth, gutter, items);
      }

      // slide styles
      var str = horizontal && !autoWidth ? getSlideWidthStyle(fixedWidth, gutter, items) : '';
      if (gutter) {
        str += getSlideGutterStyle(gutter);
      }

      // append to the last line
      if (str) {
        addCSSRule(sheet, '#' + slideId + ' > .tns-item', str, getCssRulesLength(sheet));
      }
    }

    // ## MEDIAQUERIES
    if (responsive && CSSMQ) {
      for (var bp in responsive) {
        // bp: convert string to number
        bp = parseInt(bp);

        var opts = responsive[bp],
            str = '',
            middleWrapperStr = '',
            innerWrapperStr = '',
            containerStr = '',
            slideStr = '',
            itemsBP = !autoWidth ? getOption('items', bp) : null,
            fixedWidthBP = getOption('fixedWidth', bp),
            speedBP = getOption('speed', bp),
            edgePaddingBP = getOption('edgePadding', bp),
            autoHeightBP = getOption('autoHeight', bp),
            gutterBP = getOption('gutter', bp);

        // middle wrapper string
        if (TRANSITIONDURATION && middleWrapper && getOption('autoHeight', bp) && 'speed' in opts) {
          middleWrapperStr = '#' + slideId + '-mw{' + getTransitionDurationStyle(speedBP) + '}';
        }

        // inner wrapper string
        if ('edgePadding' in opts || 'gutter' in opts) {
          innerWrapperStr = '#' + slideId + '-iw{' + getInnerWrapperStyles(edgePaddingBP, gutterBP, fixedWidthBP, speedBP, autoHeightBP) + '}';
        }

        // container string
        if (carousel && horizontal && !autoWidth && ('fixedWidth' in opts || 'items' in opts || fixedWidth && 'gutter' in opts)) {
          containerStr = 'width:' + getContainerWidth(fixedWidthBP, gutterBP, itemsBP) + ';';
        }
        if (TRANSITIONDURATION && 'speed' in opts) {
          containerStr += getTransitionDurationStyle(speedBP);
        }
        if (containerStr) {
          containerStr = '#' + slideId + '{' + containerStr + '}';
        }

        // slide string
        if ('fixedWidth' in opts || fixedWidth && 'gutter' in opts || !carousel && 'items' in opts) {
          slideStr += getSlideWidthStyle(fixedWidthBP, gutterBP, itemsBP);
        }
        if ('gutter' in opts) {
          slideStr += getSlideGutterStyle(gutterBP);
        }
        // set gallery items transition-duration
        if (!carousel && 'speed' in opts) {
          if (TRANSITIONDURATION) {
            slideStr += getTransitionDurationStyle(speedBP);
          }
          if (ANIMATIONDURATION) {
            slideStr += getAnimationDurationStyle(speedBP);
          }
        }
        if (slideStr) {
          slideStr = '#' + slideId + ' > .tns-item{' + slideStr + '}';
        }

        // add up
        str = middleWrapperStr + innerWrapperStr + containerStr + slideStr;

        if (str) {
          sheet.insertRule('@media (min-width: ' + bp / 16 + 'em) {' + str + '}', sheet.cssRules.length);
        }
      }
    }
  }

  function initTools() {
    // == slides ==
    updateSlideStatus();

    // == live region ==
    outerWrapper.insertAdjacentHTML('afterbegin', '<div class="tns-liveregion tns-visually-hidden" aria-live="polite" aria-atomic="true">slide <span class="current">' + getLiveRegionStr() + '</span>  of ' + slideCount + '</div>');
    liveregionCurrent = outerWrapper.querySelector('.tns-liveregion .current');

    // == autoplayInit ==
    if (hasAutoplay) {
      var txt = autoplay ? 'stop' : 'start';
      if (autoplayButton) {
        setAttrs(autoplayButton, { 'data-action': txt });
      } else if (options.autoplayButtonOutput) {
        outerWrapper.insertAdjacentHTML(getInsertPosition(options.autoplayPosition), '<button data-action="' + txt + '">' + autoplayHtmlStrings[0] + txt + autoplayHtmlStrings[1] + autoplayText[0] + '</button>');
        autoplayButton = outerWrapper.querySelector('[data-action]');
      }

      // add event
      if (autoplayButton) {
        addEvents(autoplayButton, { 'click': toggleAutoplay });
      }

      if (autoplay) {
        startAutoplay();
        if (autoplayHoverPause) {
          addEvents(container, hoverEvents);
        }
        if (autoplayResetOnVisibility) {
          addEvents(container, visibilityEvent);
        }
      }
    }

    // == navInit ==
    if (hasNav) {
      // customized nav
      // will not hide the navs in case they're thumbnails
      if (navContainer) {
        setAttrs(navContainer, { 'aria-label': 'Carousel Pagination' });
        navItems = navContainer.children;
        forEach(navItems, function (item, i) {
          setAttrs(item, {
            'data-nav': i,
            'tabindex': '-1',
            'aria-label': navStr + (i + 1),
            'aria-controls': slideId
          });
        });

        // generated nav 
      } else {
        var navHtml = '',
            hiddenStr = navAsThumbnails ? '' : 'style="display:none"';
        for (var i = 0; i < slideCount; i++) {
          // hide nav items by default
          navHtml += '<button data-nav="' + i + '" tabindex="-1" aria-controls="' + slideId + '" ' + hiddenStr + ' aria-label="' + navStr + (i + 1) + '"></button>';
        }
        navHtml = '<div class="tns-nav" aria-label="Carousel Pagination">' + navHtml + '</div>';
        outerWrapper.insertAdjacentHTML(getInsertPosition(options.navPosition), navHtml);

        navContainer = outerWrapper.querySelector('.tns-nav');
        navItems = navContainer.children;
      }

      updateNavVisibility();

      // add transition
      if (TRANSITIONDURATION) {
        var prefix = TRANSITIONDURATION.substring(0, TRANSITIONDURATION.length - 18).toLowerCase(),
            str = 'transition: all ' + speed / 1000 + 's';

        if (prefix) {
          str = '-' + prefix + '-' + str;
        }

        addCSSRule(sheet, '[aria-controls^=' + slideId + '-item]', str, getCssRulesLength(sheet));
      }

      setAttrs(navItems[navCurrentIndex], { 'aria-label': navStr + (navCurrentIndex + 1) + navStrCurrent });
      removeAttrs(navItems[navCurrentIndex], 'tabindex');
      addClass(navItems[navCurrentIndex], navActiveClass);

      // add events
      addEvents(navContainer, navEvents);
    }

    // == controlsInit ==
    if (hasControls) {
      if (!controlsContainer && (!prevButton || !nextButton)) {
        outerWrapper.insertAdjacentHTML(getInsertPosition(options.controlsPosition), '<div class="tns-controls" aria-label="Carousel Navigation" tabindex="0"><button data-controls="prev" tabindex="-1" aria-controls="' + slideId + '">' + controlsText[0] + '</button><button data-controls="next" tabindex="-1" aria-controls="' + slideId + '">' + controlsText[1] + '</button></div>');

        controlsContainer = outerWrapper.querySelector('.tns-controls');
      }

      if (!prevButton || !nextButton) {
        prevButton = controlsContainer.children[0];
        nextButton = controlsContainer.children[1];
      }

      if (options.controlsContainer) {
        setAttrs(controlsContainer, {
          'aria-label': 'Carousel Navigation',
          'tabindex': '0'
        });
      }

      if (options.controlsContainer || options.prevButton && options.nextButton) {
        setAttrs([prevButton, nextButton], {
          'aria-controls': slideId,
          'tabindex': '-1'
        });
      }

      if (options.controlsContainer || options.prevButton && options.nextButton) {
        setAttrs(prevButton, { 'data-controls': 'prev' });
        setAttrs(nextButton, { 'data-controls': 'next' });
      }

      prevIsButton = isButton(prevButton);
      nextIsButton = isButton(nextButton);

      updateControlsStatus();

      // add events
      if (controlsContainer) {
        addEvents(controlsContainer, controlsEvents);
      } else {
        addEvents(prevButton, controlsEvents);
        addEvents(nextButton, controlsEvents);
      }
    }

    // hide tools if needed
    disableUI();
  }

  function initEvents() {
    // add events
    if (carousel && TRANSITIONEND) {
      var eve = {};
      eve[TRANSITIONEND] = onTransitionEnd;
      addEvents(container, eve);
    }

    if (touch) {
      addEvents(container, touchEvents, options.preventScrollOnTouch);
    }
    if (mouseDrag) {
      addEvents(container, dragEvents);
    }
    if (arrowKeys) {
      addEvents(doc, docmentKeydownEvent);
    }

    if (nested === 'inner') {
      events.on('outerResized', function () {
        resizeTasks();
        events.emit('innerLoaded', info());
      });
    } else if (responsive || fixedWidth || autoWidth || autoHeight || !horizontal) {
      addEvents(win, { 'resize': onResize });
    }

    if (autoHeight) {
      if (nested === 'outer') {
        events.on('innerLoaded', doAutoHeight);
      } else if (!disable) {
        doAutoHeight();
      }
    }

    doLazyLoad();
    if (disable) {
      disableSlider();
    } else if (freeze) {
      freezeSlider();
    }

    events.on('indexChanged', additionalUpdates);
    if (nested === 'inner') {
      events.emit('innerLoaded', info());
    }
    if (typeof onInit === 'function') {
      onInit(info());
    }
    isOn = true;
  }

  function destroy() {
    // sheet
    sheet.disabled = true;
    if (sheet.ownerNode) {
      sheet.ownerNode.remove();
    }

    // remove win event listeners
    removeEvents(win, { 'resize': onResize });

    // arrowKeys, controls, nav
    if (arrowKeys) {
      removeEvents(doc, docmentKeydownEvent);
    }
    if (controlsContainer) {
      removeEvents(controlsContainer, controlsEvents);
    }
    if (navContainer) {
      removeEvents(navContainer, navEvents);
    }

    // autoplay
    removeEvents(container, hoverEvents);
    removeEvents(container, visibilityEvent);
    if (autoplayButton) {
      removeEvents(autoplayButton, { 'click': toggleAutoplay });
    }
    if (autoplay) {
      clearInterval(autoplayTimer);
    }

    // container
    if (carousel && TRANSITIONEND) {
      var eve = {};
      eve[TRANSITIONEND] = onTransitionEnd;
      removeEvents(container, eve);
    }
    if (touch) {
      removeEvents(container, touchEvents);
    }
    if (mouseDrag) {
      removeEvents(container, dragEvents);
    }

    // cache Object values in options && reset HTML
    var htmlList = [containerHTML, controlsContainerHTML, prevButtonHTML, nextButtonHTML, navContainerHTML, autoplayButtonHTML];

    tnsList.forEach(function (item, i) {
      var el = item === 'container' ? outerWrapper : options[item];

      if (typeof el === 'object') {
        var prevEl = el.previousElementSibling ? el.previousElementSibling : false,
            parentEl = el.parentNode;
        el.outerHTML = htmlList[i];
        options[item] = prevEl ? prevEl.nextElementSibling : parentEl.firstElementChild;
      }
    });

    // reset variables
    tnsList = animateIn = animateOut = animateDelay = animateNormal = horizontal = outerWrapper = innerWrapper = container = containerParent = containerHTML = slideItems = slideCount = breakpointZone = windowWidth = autoWidth = fixedWidth = edgePadding = gutter = viewport = items = slideBy = viewportMax = arrowKeys = speed = rewind = loop = autoHeight = sheet = lazyload = slidePositions = slideItemsOut = cloneCount = slideCountNew = hasRightDeadZone = rightBoundary = updateIndexBeforeTransform = transformAttr = transformPrefix = transformPostfix = getIndexMax = index = indexCached = indexMin = indexMax = resizeTimer = swipeAngle = moveDirectionExpected = running = onInit = events = newContainerClasses = slideId = disable = disabled = freezable = freeze = frozen = controlsEvents = navEvents = hoverEvents = visibilityEvent = docmentKeydownEvent = touchEvents = dragEvents = hasControls = hasNav = navAsThumbnails = hasAutoplay = hasTouch = hasMouseDrag = slideActiveClass = imgCompleteClass = imgEvents = imgsComplete = controls = controlsText = controlsContainer = controlsContainerHTML = prevButton = nextButton = prevIsButton = nextIsButton = nav = navContainer = navContainerHTML = navItems = pages = pagesCached = navClicked = navCurrentIndex = navCurrentIndexCached = navActiveClass = navStr = navStrCurrent = autoplay = autoplayTimeout = autoplayDirection = autoplayText = autoplayHoverPause = autoplayButton = autoplayButtonHTML = autoplayResetOnVisibility = autoplayHtmlStrings = autoplayTimer = animating = autoplayHoverPaused = autoplayUserPaused = autoplayVisibilityPaused = initPosition = lastPosition = translateInit = disX = disY = panStart = rafIndex = getDist = touch = mouseDrag = null;
    // check variables
    // [animateIn, animateOut, animateDelay, animateNormal, horizontal, outerWrapper, innerWrapper, container, containerParent, containerHTML, slideItems, slideCount, breakpointZone, windowWidth, autoWidth, fixedWidth, edgePadding, gutter, viewport, items, slideBy, viewportMax, arrowKeys, speed, rewind, loop, autoHeight, sheet, lazyload, slidePositions, slideItemsOut, cloneCount, slideCountNew, hasRightDeadZone, rightBoundary, updateIndexBeforeTransform, transformAttr, transformPrefix, transformPostfix, getIndexMax, index, indexCached, indexMin, indexMax, resizeTimer, swipeAngle, moveDirectionExpected, running, onInit, events, newContainerClasses, slideId, disable, disabled, freezable, freeze, frozen, controlsEvents, navEvents, hoverEvents, visibilityEvent, docmentKeydownEvent, touchEvents, dragEvents, hasControls, hasNav, navAsThumbnails, hasAutoplay, hasTouch, hasMouseDrag, slideActiveClass, imgCompleteClass, imgEvents, imgsComplete, controls, controlsText, controlsContainer, controlsContainerHTML, prevButton, nextButton, prevIsButton, nextIsButton, nav, navContainer, navContainerHTML, navItems, pages, pagesCached, navClicked, navCurrentIndex, navCurrentIndexCached, navActiveClass, navStr, navStrCurrent, autoplay, autoplayTimeout, autoplayDirection, autoplayText, autoplayHoverPause, autoplayButton, autoplayButtonHTML, autoplayResetOnVisibility, autoplayHtmlStrings, autoplayTimer, animating, autoplayHoverPaused, autoplayUserPaused, autoplayVisibilityPaused, initPosition, lastPosition, translateInit, disX, disY, panStart, rafIndex, getDist, touch, mouseDrag ].forEach(function(item) { if (item !== null) { console.log(item); } });

    for (var a in this) {
      if (a !== 'rebuild') {
        this[a] = null;
      }
    }
    isOn = false;
  }

  // === ON RESIZE ===
  // responsive || fixedWidth || autoWidth || !horizontal
  function onResize(e) {
    raf(function () {
      resizeTasks(getEvent(e));
    });
  }

  function resizeTasks(e) {
    if (!isOn) {
      return;
    }
    if (nested === 'outer') {
      events.emit('outerResized', info(e));
    }
    windowWidth = getWindowWidth();
    var bpChanged,
        breakpointZoneTem = breakpointZone,
        needContainerTransform = false;

    if (responsive) {
      setBreakpointZone();
      bpChanged = breakpointZoneTem !== breakpointZone;
      // if (hasRightDeadZone) { needContainerTransform = true; } // *?
      if (bpChanged) {
        events.emit('newBreakpointStart', info(e));
      }
    }

    var indChanged,
        itemsChanged,
        itemsTem = items,
        disableTem = disable,
        freezeTem = freeze,
        arrowKeysTem = arrowKeys,
        controlsTem = controls,
        navTem = nav,
        touchTem = touch,
        mouseDragTem = mouseDrag,
        autoplayTem = autoplay,
        autoplayHoverPauseTem = autoplayHoverPause,
        autoplayResetOnVisibilityTem = autoplayResetOnVisibility,
        indexTem = index;

    if (bpChanged) {
      var fixedWidthTem = fixedWidth,
          autoHeightTem = autoHeight,
          controlsTextTem = controlsText,
          centerTem = center,
          autoplayTextTem = autoplayText;

      if (!CSSMQ) {
        var gutterTem = gutter,
            edgePaddingTem = edgePadding;
      }
    }

    // get option:
    // fixed width: viewport, fixedWidth, gutter => items
    // others: window width => all variables
    // all: items => slideBy
    arrowKeys = getOption('arrowKeys');
    controls = getOption('controls');
    nav = getOption('nav');
    touch = getOption('touch');
    center = getOption('center');
    mouseDrag = getOption('mouseDrag');
    autoplay = getOption('autoplay');
    autoplayHoverPause = getOption('autoplayHoverPause');
    autoplayResetOnVisibility = getOption('autoplayResetOnVisibility');

    if (bpChanged) {
      disable = getOption('disable');
      fixedWidth = getOption('fixedWidth');
      speed = getOption('speed');
      autoHeight = getOption('autoHeight');
      controlsText = getOption('controlsText');
      autoplayText = getOption('autoplayText');
      autoplayTimeout = getOption('autoplayTimeout');

      if (!CSSMQ) {
        edgePadding = getOption('edgePadding');
        gutter = getOption('gutter');
      }
    }
    // update options
    resetVariblesWhenDisable(disable);

    viewport = getViewportWidth(); // <= edgePadding, gutter
    if ((!horizontal || autoWidth) && !disable) {
      setSlidePositions();
      if (!horizontal) {
        updateContentWrapperHeight(); // <= setSlidePositions
        needContainerTransform = true;
      }
    }
    if (fixedWidth || autoWidth) {
      rightBoundary = getRightBoundary(); // autoWidth: <= viewport, slidePositions, gutter
      // fixedWidth: <= viewport, fixedWidth, gutter
      indexMax = getIndexMax(); // autoWidth: <= rightBoundary, slidePositions
      // fixedWidth: <= rightBoundary, fixedWidth, gutter
    }

    if (bpChanged || fixedWidth) {
      items = getOption('items');
      slideBy = getOption('slideBy');
      itemsChanged = items !== itemsTem;

      if (itemsChanged) {
        if (!fixedWidth && !autoWidth) {
          indexMax = getIndexMax();
        } // <= items
        // check index before transform in case
        // slider reach the right edge then items become bigger
        updateIndex();
      }
    }

    if (bpChanged) {
      if (disable !== disableTem) {
        if (disable) {
          disableSlider();
        } else {
          enableSlider(); // <= slidePositions, rightBoundary, indexMax
        }
      }
    }

    if (freezable && (bpChanged || fixedWidth || autoWidth)) {
      freeze = getFreeze(); // <= autoWidth: slidePositions, gutter, viewport, rightBoundary
      // <= fixedWidth: fixedWidth, gutter, rightBoundary
      // <= others: items

      if (freeze !== freezeTem) {
        if (freeze) {
          doContainerTransform(getContainerTransformValue(getStartIndex(0)));
          freezeSlider();
        } else {
          unfreezeSlider();
          needContainerTransform = true;
        }
      }
    }

    resetVariblesWhenDisable(disable || freeze); // controls, nav, touch, mouseDrag, arrowKeys, autoplay, autoplayHoverPause, autoplayResetOnVisibility
    if (!autoplay) {
      autoplayHoverPause = autoplayResetOnVisibility = false;
    }

    if (arrowKeys !== arrowKeysTem) {
      arrowKeys ? addEvents(doc, docmentKeydownEvent) : removeEvents(doc, docmentKeydownEvent);
    }
    if (controls !== controlsTem) {
      if (controls) {
        if (controlsContainer) {
          showElement(controlsContainer);
        } else {
          if (prevButton) {
            showElement(prevButton);
          }
          if (nextButton) {
            showElement(nextButton);
          }
        }
      } else {
        if (controlsContainer) {
          hideElement(controlsContainer);
        } else {
          if (prevButton) {
            hideElement(prevButton);
          }
          if (nextButton) {
            hideElement(nextButton);
          }
        }
      }
    }
    if (nav !== navTem) {
      nav ? showElement(navContainer) : hideElement(navContainer);
    }
    if (touch !== touchTem) {
      touch ? addEvents(container, touchEvents, options.preventScrollOnTouch) : removeEvents(container, touchEvents);
    }
    if (mouseDrag !== mouseDragTem) {
      mouseDrag ? addEvents(container, dragEvents) : removeEvents(container, dragEvents);
    }
    if (autoplay !== autoplayTem) {
      if (autoplay) {
        if (autoplayButton) {
          showElement(autoplayButton);
        }
        if (!animating && !autoplayUserPaused) {
          startAutoplay();
        }
      } else {
        if (autoplayButton) {
          hideElement(autoplayButton);
        }
        if (animating) {
          stopAutoplay();
        }
      }
    }
    if (autoplayHoverPause !== autoplayHoverPauseTem) {
      autoplayHoverPause ? addEvents(container, hoverEvents) : removeEvents(container, hoverEvents);
    }
    if (autoplayResetOnVisibility !== autoplayResetOnVisibilityTem) {
      autoplayResetOnVisibility ? addEvents(doc, visibilityEvent) : removeEvents(doc, visibilityEvent);
    }

    if (bpChanged) {
      if (fixedWidth !== fixedWidthTem || center !== centerTem) {
        needContainerTransform = true;
      }

      if (autoHeight !== autoHeightTem) {
        if (!autoHeight) {
          innerWrapper.style.height = '';
        }
      }

      if (controls && controlsText !== controlsTextTem) {
        prevButton.innerHTML = controlsText[0];
        nextButton.innerHTML = controlsText[1];
      }

      if (autoplayButton && autoplayText !== autoplayTextTem) {
        var i = autoplay ? 1 : 0,
            html = autoplayButton.innerHTML,
            len = html.length - autoplayTextTem[i].length;
        if (html.substring(len) === autoplayTextTem[i]) {
          autoplayButton.innerHTML = html.substring(0, len) + autoplayText[i];
        }
      }
    } else {
      if (center && (fixedWidth || autoWidth)) {
        needContainerTransform = true;
      }
    }

    if (itemsChanged || fixedWidth && !autoWidth) {
      pages = getPages();
      updateNavVisibility();
    }

    indChanged = index !== indexTem;
    if (indChanged) {
      events.emit('indexChanged', info());
      needContainerTransform = true;
    } else if (itemsChanged) {
      if (!indChanged) {
        additionalUpdates();
      }
    } else if (fixedWidth || autoWidth) {
      doLazyLoad();
      updateSlideStatus();
      updateLiveRegion();
    }

    if (itemsChanged || !carousel) {
      updateGallerySlidePositions();
    }

    if (!disable && !freeze) {
      // non-meduaqueries: IE8
      if (bpChanged && !CSSMQ) {
        // middle wrapper styles
        if (autoHeight !== autoheightTem || speed !== speedTem) {
          update_carousel_transition_duration();
        }

        // inner wrapper styles
        if (edgePadding !== edgePaddingTem || gutter !== gutterTem) {
          innerWrapper.style.cssText = getInnerWrapperStyles(edgePadding, gutter, fixedWidth, speed, autoHeight);
        }

        if (horizontal) {
          // container styles
          if (carousel) {
            container.style.width = getContainerWidth(fixedWidth, gutter, items);
          }

          // slide styles
          var str = getSlideWidthStyle(fixedWidth, gutter, items) + getSlideGutterStyle(gutter);

          // remove the last line and
          // add new styles
          removeCSSRule(sheet, getCssRulesLength(sheet) - 1);
          addCSSRule(sheet, '#' + slideId + ' > .tns-item', str, getCssRulesLength(sheet));
        }
      }

      // auto height
      if (autoHeight) {
        doAutoHeight();
      }

      if (needContainerTransform) {
        doContainerTransformSilent();
        indexCached = index;
      }
    }

    if (bpChanged) {
      events.emit('newBreakpointEnd', info(e));
    }
  }

  // === INITIALIZATION FUNCTIONS === //
  function getFreeze() {
    if (!fixedWidth && !autoWidth) {
      var a = center ? items - (items - 1) / 2 : items;
      return slideCount <= a;
    }

    var width = fixedWidth ? (fixedWidth + gutter) * slideCount : slidePositions[slideCount],
        vp = edgePadding ? viewport + edgePadding * 2 : viewport + gutter;

    if (center) {
      vp -= fixedWidth ? (viewport - fixedWidth) / 2 : (viewport - (slidePositions[index + 1] - slidePositions[index] - gutter)) / 2;
    }

    return width <= vp;
  }

  function setBreakpointZone() {
    breakpointZone = 0;
    for (var bp in responsive) {
      bp = parseInt(bp); // convert string to number
      if (windowWidth >= bp) {
        breakpointZone = bp;
      }
    }
  }

  // (slideBy, indexMin, indexMax) => index
  var updateIndex = function () {
    return loop ? carousel ?
    // loop + carousel
    function () {
      var leftEdge = indexMin,
          rightEdge = indexMax;

      leftEdge += slideBy;
      rightEdge -= slideBy;

      // adjust edges when has edge paddings
      // or fixed-width slider with extra space on the right side
      if (edgePadding) {
        leftEdge += 1;
        rightEdge -= 1;
      } else if (fixedWidth) {
        if ((viewport + gutter) % (fixedWidth + gutter)) {
          rightEdge -= 1;
        }
      }

      if (cloneCount) {
        if (index > rightEdge) {
          index -= slideCount;
        } else if (index < leftEdge) {
          index += slideCount;
        }
      }
    } :
    // loop + gallery
    function () {
      if (index > indexMax) {
        while (index >= indexMin + slideCount) {
          index -= slideCount;
        }
      } else if (index < indexMin) {
        while (index <= indexMax - slideCount) {
          index += slideCount;
        }
      }
    } :
    // non-loop
    function () {
      index = Math.max(indexMin, Math.min(indexMax, index));
    };
  }();

  function disableUI() {
    if (!autoplay && autoplayButton) {
      hideElement(autoplayButton);
    }
    if (!nav && navContainer) {
      hideElement(navContainer);
    }
    if (!controls) {
      if (controlsContainer) {
        hideElement(controlsContainer);
      } else {
        if (prevButton) {
          hideElement(prevButton);
        }
        if (nextButton) {
          hideElement(nextButton);
        }
      }
    }
  }

  function enableUI() {
    if (autoplay && autoplayButton) {
      showElement(autoplayButton);
    }
    if (nav && navContainer) {
      showElement(navContainer);
    }
    if (controls) {
      if (controlsContainer) {
        showElement(controlsContainer);
      } else {
        if (prevButton) {
          showElement(prevButton);
        }
        if (nextButton) {
          showElement(nextButton);
        }
      }
    }
  }

  function freezeSlider() {
    if (frozen) {
      return;
    }

    // remove edge padding from inner wrapper
    if (edgePadding) {
      innerWrapper.style.margin = '0px';
    }

    // add class tns-transparent to cloned slides
    if (cloneCount) {
      var str = 'tns-transparent';
      for (var i = cloneCount; i--;) {
        if (carousel) {
          addClass(slideItems[i], str);
        }
        addClass(slideItems[slideCountNew - i - 1], str);
      }
    }

    // update tools
    disableUI();

    frozen = true;
  }

  function unfreezeSlider() {
    if (!frozen) {
      return;
    }

    // restore edge padding for inner wrapper
    // for mordern browsers
    if (edgePadding && CSSMQ) {
      innerWrapper.style.margin = '';
    }

    // remove class tns-transparent to cloned slides
    if (cloneCount) {
      var str = 'tns-transparent';
      for (var i = cloneCount; i--;) {
        if (carousel) {
          removeClass(slideItems[i], str);
        }
        removeClass(slideItems[slideCountNew - i - 1], str);
      }
    }

    // update tools
    enableUI();

    frozen = false;
  }

  function disableSlider() {
    if (disabled) {
      return;
    }

    sheet.disabled = true;
    container.className = container.className.replace(newContainerClasses.substring(1), '');
    removeAttrs(container, ['style']);
    if (loop) {
      for (var j = cloneCount; j--;) {
        if (carousel) {
          hideElement(slideItems[j]);
        }
        hideElement(slideItems[slideCountNew - j - 1]);
      }
    }

    // vertical slider
    if (!horizontal || !carousel) {
      removeAttrs(innerWrapper, ['style']);
    }

    // gallery
    if (!carousel) {
      for (var i = index, l = index + slideCount; i < l; i++) {
        var item = slideItems[i];
        removeAttrs(item, ['style']);
        removeClass(item, animateIn);
        removeClass(item, animateNormal);
      }
    }

    // update tools
    disableUI();

    disabled = true;
  }

  function enableSlider() {
    if (!disabled) {
      return;
    }

    sheet.disabled = false;
    container.className += newContainerClasses;
    doContainerTransformSilent();

    if (loop) {
      for (var j = cloneCount; j--;) {
        if (carousel) {
          showElement(slideItems[j]);
        }
        showElement(slideItems[slideCountNew - j - 1]);
      }
    }

    // gallery
    if (!carousel) {
      for (var i = index, l = index + slideCount; i < l; i++) {
        var item = slideItems[i],
            classN = i < index + items ? animateIn : animateNormal;
        item.style.left = (i - index) * 100 / items + '%';
        addClass(item, classN);
      }
    }

    // update tools
    enableUI();

    disabled = false;
  }

  function updateLiveRegion() {
    var str = getLiveRegionStr();
    if (liveregionCurrent.innerHTML !== str) {
      liveregionCurrent.innerHTML = str;
    }
  }

  function getLiveRegionStr() {
    var arr = getVisibleSlideRange(),
        start = arr[0] + 1,
        end = arr[1] + 1;
    return start === end ? start + '' : start + ' to ' + end;
  }

  function getVisibleSlideRange(val) {
    if (val == null) {
      val = getContainerTransformValue();
    }
    var start = index,
        end,
        rangestart,
        rangeend;

    // get range start, range end for autoWidth and fixedWidth
    if (center || edgePadding) {
      if (autoWidth || fixedWidth) {
        rangestart = -(parseFloat(val) + edgePadding);
        rangeend = rangestart + viewport + edgePadding * 2;
      }
    } else {
      if (autoWidth) {
        rangestart = slidePositions[index];
        rangeend = rangestart + viewport;
      }
    }

    // get start, end
    // - check auto width
    if (autoWidth) {
      slidePositions.forEach(function (point, i) {
        if (i < slideCountNew) {
          if ((center || edgePadding) && point <= rangestart + 0.5) {
            start = i;
          }
          if (rangeend - point >= 0.5) {
            end = i;
          }
        }
      });

      // - check percentage width, fixed width
    } else {

      if (fixedWidth) {
        var cell = fixedWidth + gutter;
        if (center || edgePadding) {
          start = Math.floor(rangestart / cell);
          end = Math.ceil(rangeend / cell - 1);
        } else {
          end = start + Math.ceil(viewport / cell) - 1;
        }
      } else {
        if (center || edgePadding) {
          var a = items - 1;
          if (center) {
            start -= a / 2;
            end = index + a / 2;
          } else {
            end = index + a;
          }

          if (edgePadding) {
            var b = edgePadding * items / viewport;
            start -= b;
            end += b;
          }

          start = Math.floor(start);
          end = Math.ceil(end);
        } else {
          end = start + items - 1;
        }
      }

      start = Math.max(start, 0);
      end = Math.min(end, slideCountNew - 1);
    }

    return [start, end];
  }

  function doLazyLoad() {
    if (lazyload && !disable) {
      getImageArray.apply(null, getVisibleSlideRange()).forEach(function (img) {
        if (!hasClass(img, imgCompleteClass)) {
          // stop propagation transitionend event to container
          var eve = {};
          eve[TRANSITIONEND] = function (e) {
            e.stopPropagation();
          };
          addEvents(img, eve);

          addEvents(img, imgEvents);

          // update src
          img.src = getAttr(img, 'data-src');

          // update srcset
          var srcset = getAttr(img, 'data-srcset');
          if (srcset) {
            img.srcset = srcset;
          }

          addClass(img, 'loading');
        }
      });
    }
  }

  function onImgLoaded(e) {
    imgLoaded(getTarget(e));
  }

  function onImgFailed(e) {
    imgFailed(getTarget(e));
  }

  function imgLoaded(img) {
    addClass(img, 'loaded');
    imgCompleted(img);
  }

  function imgFailed(img) {
    addClass(img, 'failed');
    imgCompleted(img);
  }

  function imgCompleted(img) {
    addClass(img, 'tns-complete');
    removeClass(img, 'loading');
    removeEvents(img, imgEvents);
  }

  function getImageArray(start, end) {
    var imgs = [];
    while (start <= end) {
      forEach(slideItems[start].querySelectorAll('img'), function (img) {
        imgs.push(img);
      });
      start++;
    }

    return imgs;
  }

  // check if all visible images are loaded
  // and update container height if it's done
  function doAutoHeight() {
    var imgs = getImageArray.apply(null, getVisibleSlideRange());
    raf(function () {
      imgsLoadedCheck(imgs, updateInnerWrapperHeight);
    });
  }

  function imgsLoadedCheck(imgs, cb) {
    // directly execute callback function if all images are complete
    if (imgsComplete) {
      return cb();
    }

    // check selected image classes otherwise
    imgs.forEach(function (img, index) {
      if (hasClass(img, imgCompleteClass)) {
        imgs.splice(index, 1);
      }
    });

    // execute callback function if selected images are all complete
    if (!imgs.length) {
      return cb();
    }

    // otherwise execute this functiona again
    raf(function () {
      imgsLoadedCheck(imgs, cb);
    });
  }

  function additionalUpdates() {
    doLazyLoad();
    updateSlideStatus();
    updateLiveRegion();
    updateControlsStatus();
    updateNavStatus();
  }

  function update_carousel_transition_duration() {
    if (carousel && autoHeight) {
      middleWrapper.style[TRANSITIONDURATION] = speed / 1000 + 's';
    }
  }

  function getMaxSlideHeight(slideStart, slideRange) {
    var heights = [];
    for (var i = slideStart, l = Math.min(slideStart + slideRange, slideCountNew); i < l; i++) {
      heights.push(slideItems[i].offsetHeight);
    }

    return Math.max.apply(null, heights);
  }

  // update inner wrapper height
  // 1. get the max-height of the visible slides
  // 2. set transitionDuration to speed
  // 3. update inner wrapper height to max-height
  // 4. set transitionDuration to 0s after transition done
  function updateInnerWrapperHeight() {
    var maxHeight = autoHeight ? getMaxSlideHeight(index, items) : getMaxSlideHeight(cloneCount, slideCount),
        wp = middleWrapper ? middleWrapper : innerWrapper;

    if (wp.style.height !== maxHeight) {
      wp.style.height = maxHeight + 'px';
    }
  }

  // get the distance from the top edge of the first slide to each slide
  // (init) => slidePositions
  function setSlidePositions() {
    slidePositions = [0];
    var attr = horizontal ? 'left' : 'top',
        attr2 = horizontal ? 'right' : 'bottom',
        base = slideItems[0].getBoundingClientRect()[attr];

    forEach(slideItems, function (item, i) {
      // skip the first slide
      if (i) {
        slidePositions.push(item.getBoundingClientRect()[attr] - base);
      }
      // add the end edge
      if (i === slideCountNew - 1) {
        slidePositions.push(item.getBoundingClientRect()[attr2] - base);
      }
    });
  }

  // update slide
  function updateSlideStatus() {
    var range = getVisibleSlideRange(),
        start = range[0],
        end = range[1];

    forEach(slideItems, function (item, i) {
      // show slides
      if (i >= start && i <= end) {
        if (hasAttr(item, 'aria-hidden')) {
          removeAttrs(item, ['aria-hidden', 'tabindex']);
          addClass(item, slideActiveClass);
        }
        // hide slides
      } else {
        if (!hasAttr(item, 'aria-hidden')) {
          setAttrs(item, {
            'aria-hidden': 'true',
            'tabindex': '-1'
          });
          removeClass(item, slideActiveClass);
        }
      }
    });
  }

  // gallery: update slide position
  function updateGallerySlidePositions() {
    var l = index + Math.min(slideCount, items);
    for (var i = slideCountNew; i--;) {
      var item = slideItems[i];

      if (i >= index && i < l) {
        // add transitions to visible slides when adjusting their positions
        addClass(item, 'tns-moving');

        item.style.left = (i - index) * 100 / items + '%';
        addClass(item, animateIn);
        removeClass(item, animateNormal);
      } else if (item.style.left) {
        item.style.left = '';
        addClass(item, animateNormal);
        removeClass(item, animateIn);
      }

      // remove outlet animation
      removeClass(item, animateOut);
    }

    // removing '.tns-moving'
    setTimeout(function () {
      forEach(slideItems, function (el) {
        removeClass(el, 'tns-moving');
      });
    }, 300);
  }

  // set tabindex on Nav
  function updateNavStatus() {
    // get current nav
    if (nav) {
      navCurrentIndex = navClicked >= 0 ? navClicked : getCurrentNavIndex();
      navClicked = -1;

      if (navCurrentIndex !== navCurrentIndexCached) {
        var navPrev = navItems[navCurrentIndexCached],
            navCurrent = navItems[navCurrentIndex];

        setAttrs(navPrev, {
          'tabindex': '-1',
          'aria-label': navStr + (navCurrentIndexCached + 1)
        });
        removeClass(navPrev, navActiveClass);

        setAttrs(navCurrent, { 'aria-label': navStr + (navCurrentIndex + 1) + navStrCurrent });
        removeAttrs(navCurrent, 'tabindex');
        addClass(navCurrent, navActiveClass);

        navCurrentIndexCached = navCurrentIndex;
      }
    }
  }

  function getLowerCaseNodeName(el) {
    return el.nodeName.toLowerCase();
  }

  function isButton(el) {
    return getLowerCaseNodeName(el) === 'button';
  }

  function isAriaDisabled(el) {
    return el.getAttribute('aria-disabled') === 'true';
  }

  function disEnableElement(isButton, el, val) {
    if (isButton) {
      el.disabled = val;
    } else {
      el.setAttribute('aria-disabled', val.toString());
    }
  }

  // set 'disabled' to true on controls when reach the edges
  function updateControlsStatus() {
    if (!controls || rewind || loop) {
      return;
    }

    var prevDisabled = prevIsButton ? prevButton.disabled : isAriaDisabled(prevButton),
        nextDisabled = nextIsButton ? nextButton.disabled : isAriaDisabled(nextButton),
        disablePrev = index <= indexMin ? true : false,
        disableNext = !rewind && index >= indexMax ? true : false;

    if (disablePrev && !prevDisabled) {
      disEnableElement(prevIsButton, prevButton, true);
    }
    if (!disablePrev && prevDisabled) {
      disEnableElement(prevIsButton, prevButton, false);
    }
    if (disableNext && !nextDisabled) {
      disEnableElement(nextIsButton, nextButton, true);
    }
    if (!disableNext && nextDisabled) {
      disEnableElement(nextIsButton, nextButton, false);
    }
  }

  // set duration
  function resetDuration(el, str) {
    if (TRANSITIONDURATION) {
      el.style[TRANSITIONDURATION] = str;
    }
  }

  function getSliderWidth() {
    return fixedWidth ? (fixedWidth + gutter) * slideCountNew : slidePositions[slideCountNew];
  }

  function getCenterGap(num) {
    if (num == null) {
      num = index;
    }

    var gap = edgePadding ? gutter : 0;
    return autoWidth ? (viewport - gap - (slidePositions[num + 1] - slidePositions[num] - gutter)) / 2 : fixedWidth ? (viewport - fixedWidth) / 2 : (items - 1) / 2;
  }

  function getRightBoundary() {
    var gap = edgePadding ? gutter : 0,
        result = viewport + gap - getSliderWidth();

    if (center && !loop) {
      result = fixedWidth ? -(fixedWidth + gutter) * (slideCountNew - 1) - getCenterGap() : getCenterGap(slideCountNew - 1) - slidePositions[slideCountNew - 1];
    }
    if (result > 0) {
      result = 0;
    }

    return result;
  }

  function getContainerTransformValue(num) {
    if (num == null) {
      num = index;
    }

    var val;
    if (horizontal && !autoWidth) {
      if (fixedWidth) {
        val = -(fixedWidth + gutter) * num;
        if (center) {
          val += getCenterGap();
        }
      } else {
        var denominator = TRANSFORM ? slideCountNew : items;
        if (center) {
          num -= getCenterGap();
        }
        val = -num * 100 / denominator;
      }
    } else {
      val = -slidePositions[num];
      if (center && autoWidth) {
        val += getCenterGap();
      }
    }

    if (hasRightDeadZone) {
      val = Math.max(val, rightBoundary);
    }

    val += horizontal && !autoWidth && !fixedWidth ? '%' : 'px';

    return val;
  }

  function doContainerTransformSilent(val) {
    resetDuration(container, '0s');
    doContainerTransform(val);
  }

  function doContainerTransform(val) {
    if (val == null) {
      val = getContainerTransformValue();
    }
    container.style[transformAttr] = transformPrefix + val + transformPostfix;
  }

  function animateSlide(number, classOut, classIn, isOut) {
    var l = number + items;
    if (!loop) {
      l = Math.min(l, slideCountNew);
    }

    for (var i = number; i < l; i++) {
      var item = slideItems[i];

      // set item positions
      if (!isOut) {
        item.style.left = (i - index) * 100 / items + '%';
      }

      if (animateDelay && TRANSITIONDELAY) {
        item.style[TRANSITIONDELAY] = item.style[ANIMATIONDELAY] = animateDelay * (i - number) / 1000 + 's';
      }
      removeClass(item, classOut);
      addClass(item, classIn);

      if (isOut) {
        slideItemsOut.push(item);
      }
    }
  }

  // make transfer after click/drag:
  // 1. change 'transform' property for mordern browsers
  // 2. change 'left' property for legacy browsers
  var transformCore = function () {
    return carousel ? function () {
      resetDuration(container, '');
      if (TRANSITIONDURATION || !speed) {
        // for morden browsers with non-zero duration or 
        // zero duration for all browsers
        doContainerTransform();
        // run fallback function manually 
        // when duration is 0 / container is hidden
        if (!speed || !isVisible(container)) {
          onTransitionEnd();
        }
      } else {
        // for old browser with non-zero duration
        jsTransform(container, transformAttr, transformPrefix, transformPostfix, getContainerTransformValue(), speed, onTransitionEnd);
      }

      if (!horizontal) {
        updateContentWrapperHeight();
      }
    } : function () {
      slideItemsOut = [];

      var eve = {};
      eve[TRANSITIONEND] = eve[ANIMATIONEND] = onTransitionEnd;
      removeEvents(slideItems[indexCached], eve);
      addEvents(slideItems[index], eve);

      animateSlide(indexCached, animateIn, animateOut, true);
      animateSlide(index, animateNormal, animateIn);

      // run fallback function manually 
      // when transition or animation not supported / duration is 0
      if (!TRANSITIONEND || !ANIMATIONEND || !speed || !isVisible(container)) {
        onTransitionEnd();
      }
    };
  }();

  function render(e, sliderMoved) {
    if (updateIndexBeforeTransform) {
      updateIndex();
    }

    // render when slider was moved (touch or drag) even though index may not change
    if (index !== indexCached || sliderMoved) {
      // events
      events.emit('indexChanged', info());
      events.emit('transitionStart', info());
      if (autoHeight) {
        doAutoHeight();
      }

      // pause autoplay when click or keydown from user
      if (animating && e && ['click', 'keydown'].indexOf(e.type) >= 0) {
        stopAutoplay();
      }

      running = true;
      transformCore();
    }
  }

  /*
   * Transfer prefixed properties to the same format
   * CSS: -Webkit-Transform => webkittransform
   * JS: WebkitTransform => webkittransform
   * @param {string} str - property
   *
   */
  function strTrans(str) {
    return str.toLowerCase().replace(/-/g, '');
  }

  // AFTER TRANSFORM
  // Things need to be done after a transfer:
  // 1. check index
  // 2. add classes to visible slide
  // 3. disable controls buttons when reach the first/last slide in non-loop slider
  // 4. update nav status
  // 5. lazyload images
  // 6. update container height
  function onTransitionEnd(event) {
    // check running on gallery mode
    // make sure trantionend/animationend events run only once
    if (carousel || running) {
      events.emit('transitionEnd', info(event));

      if (!carousel && slideItemsOut.length > 0) {
        for (var i = 0; i < slideItemsOut.length; i++) {
          var item = slideItemsOut[i];
          // set item positions
          item.style.left = '';

          if (ANIMATIONDELAY && TRANSITIONDELAY) {
            item.style[ANIMATIONDELAY] = '';
            item.style[TRANSITIONDELAY] = '';
          }
          removeClass(item, animateOut);
          addClass(item, animateNormal);
        }
      }

      /* update slides, nav, controls after checking ...
       * => legacy browsers who don't support 'event' 
       *    have to check event first, otherwise event.target will cause an error 
       * => or 'gallery' mode: 
       *   + event target is slide item
       * => or 'carousel' mode: 
       *   + event target is container, 
       *   + event.property is the same with transform attribute
       */
      if (!event || !carousel && event.target.parentNode === container || event.target === container && strTrans(event.propertyName) === strTrans(transformAttr)) {

        if (!updateIndexBeforeTransform) {
          var indexTem = index;
          updateIndex();
          if (index !== indexTem) {
            events.emit('indexChanged', info());

            doContainerTransformSilent();
          }
        }

        if (nested === 'inner') {
          events.emit('innerLoaded', info());
        }
        running = false;
        indexCached = index;
      }
    }
  }

  // # ACTIONS
  function goTo(targetIndex, e) {
    if (freeze) {
      return;
    }

    // prev slideBy
    if (targetIndex === 'prev') {
      onControlsClick(e, -1);

      // next slideBy
    } else if (targetIndex === 'next') {
      onControlsClick(e, 1);

      // go to exact slide
    } else {
      if (running) {
        if (preventActionWhenRunning) {
          return;
        } else {
          onTransitionEnd();
        }
      }

      var absIndex = getAbsIndex(),
          indexGap = 0;

      if (targetIndex === 'first') {
        indexGap = -absIndex;
      } else if (targetIndex === 'last') {
        indexGap = carousel ? slideCount - items - absIndex : slideCount - 1 - absIndex;
      } else {
        if (typeof targetIndex !== 'number') {
          targetIndex = parseInt(targetIndex);
        }

        if (!isNaN(targetIndex)) {
          // from directly called goTo function
          if (!e) {
            targetIndex = Math.max(0, Math.min(slideCount - 1, targetIndex));
          }

          indexGap = targetIndex - absIndex;
        }
      }

      // gallery: make sure new page won't overlap with current page
      if (!carousel && indexGap && Math.abs(indexGap) < items) {
        var factor = indexGap > 0 ? 1 : -1;
        indexGap += index + indexGap - slideCount >= indexMin ? slideCount * factor : slideCount * 2 * factor * -1;
      }

      index += indexGap;

      // make sure index is in range
      if (carousel && loop) {
        if (index < indexMin) {
          index += slideCount;
        }
        if (index > indexMax) {
          index -= slideCount;
        }
      }

      // if index is changed, start rendering
      if (getAbsIndex(index) !== getAbsIndex(indexCached)) {
        render(e);
      }
    }
  }

  // on controls click
  function onControlsClick(e, dir) {
    if (running) {
      if (preventActionWhenRunning) {
        return;
      } else {
        onTransitionEnd();
      }
    }
    var passEventObject;

    if (!dir) {
      e = getEvent(e);
      var target = getTarget(e);

      while (target !== controlsContainer && [prevButton, nextButton].indexOf(target) < 0) {
        target = target.parentNode;
      }

      var targetIn = [prevButton, nextButton].indexOf(target);
      if (targetIn >= 0) {
        passEventObject = true;
        dir = targetIn === 0 ? -1 : 1;
      }
    }

    if (rewind) {
      if (index === indexMin && dir === -1) {
        goTo('last', e);
        return;
      } else if (index === indexMax && dir === 1) {
        goTo('first', e);
        return;
      }
    }

    if (dir) {
      index += slideBy * dir;
      if (autoWidth) {
        index = Math.floor(index);
      }
      // pass e when click control buttons or keydown
      render(passEventObject || e && e.type === 'keydown' ? e : null);
    }
  }

  // on nav click
  function onNavClick(e) {
    if (running) {
      if (preventActionWhenRunning) {
        return;
      } else {
        onTransitionEnd();
      }
    }

    e = getEvent(e);
    var target = getTarget(e),
        navIndex;

    // find the clicked nav item
    while (target !== navContainer && !hasAttr(target, 'data-nav')) {
      target = target.parentNode;
    }
    if (hasAttr(target, 'data-nav')) {
      var navIndex = navClicked = Number(getAttr(target, 'data-nav')),
          targetIndexBase = fixedWidth || autoWidth ? navIndex * slideCount / pages : navIndex * items,
          targetIndex = navAsThumbnails ? navIndex : Math.min(Math.ceil(targetIndexBase), slideCount - 1);
      goTo(targetIndex, e);

      if (navCurrentIndex === navIndex) {
        if (animating) {
          stopAutoplay();
        }
        navClicked = -1; // reset navClicked
      }
    }
  }

  // autoplay functions
  function setAutoplayTimer() {
    autoplayTimer = setInterval(function () {
      onControlsClick(null, autoplayDirection);
    }, autoplayTimeout);

    animating = true;
  }

  function stopAutoplayTimer() {
    clearInterval(autoplayTimer);
    animating = false;
  }

  function updateAutoplayButton(action, txt) {
    setAttrs(autoplayButton, { 'data-action': action });
    autoplayButton.innerHTML = autoplayHtmlStrings[0] + action + autoplayHtmlStrings[1] + txt;
  }

  function startAutoplay() {
    setAutoplayTimer();
    if (autoplayButton) {
      updateAutoplayButton('stop', autoplayText[1]);
    }
  }

  function stopAutoplay() {
    stopAutoplayTimer();
    if (autoplayButton) {
      updateAutoplayButton('start', autoplayText[0]);
    }
  }

  // programaitcally play/pause the slider
  function play() {
    if (autoplay && !animating) {
      startAutoplay();
      autoplayUserPaused = false;
    }
  }
  function pause() {
    if (animating) {
      stopAutoplay();
      autoplayUserPaused = true;
    }
  }

  function toggleAutoplay() {
    if (animating) {
      stopAutoplay();
      autoplayUserPaused = true;
    } else {
      startAutoplay();
      autoplayUserPaused = false;
    }
  }

  function onVisibilityChange() {
    if (doc.hidden) {
      if (animating) {
        stopAutoplayTimer();
        autoplayVisibilityPaused = true;
      }
    } else if (autoplayVisibilityPaused) {
      setAutoplayTimer();
      autoplayVisibilityPaused = false;
    }
  }

  function mouseoverPause() {
    if (animating) {
      stopAutoplayTimer();
      autoplayHoverPaused = true;
    }
  }

  function mouseoutRestart() {
    if (autoplayHoverPaused) {
      setAutoplayTimer();
      autoplayHoverPaused = false;
    }
  }

  // keydown events on document 
  function onDocumentKeydown(e) {
    e = getEvent(e);
    var keyIndex = [KEYS.LEFT, KEYS.RIGHT].indexOf(e.keyCode);

    if (keyIndex >= 0) {
      onControlsClick(e, keyIndex === 0 ? -1 : 1);
    }
  }

  // on key control
  function onControlsKeydown(e) {
    e = getEvent(e);
    var keyIndex = [KEYS.LEFT, KEYS.RIGHT].indexOf(e.keyCode);

    if (keyIndex >= 0) {
      if (keyIndex === 0) {
        if (!prevButton.disabled) {
          onControlsClick(e, -1);
        }
      } else if (!nextButton.disabled) {
        onControlsClick(e, 1);
      }
    }
  }

  // set focus
  function setFocus(el) {
    el.focus();
  }

  // on key nav
  function onNavKeydown(e) {
    e = getEvent(e);
    var curElement = doc.activeElement;
    if (!hasAttr(curElement, 'data-nav')) {
      return;
    }

    // var code = e.keyCode,
    var keyIndex = [KEYS.LEFT, KEYS.RIGHT, KEYS.ENTER, KEYS.SPACE].indexOf(e.keyCode),
        navIndex = Number(getAttr(curElement, 'data-nav'));

    if (keyIndex >= 0) {
      if (keyIndex === 0) {
        if (navIndex > 0) {
          setFocus(navItems[navIndex - 1]);
        }
      } else if (keyIndex === 1) {
        if (navIndex < pages - 1) {
          setFocus(navItems[navIndex + 1]);
        }
      } else {
        navClicked = navIndex;
        goTo(navIndex, e);
      }
    }
  }

  function getEvent(e) {
    e = e || win.event;
    return isTouchEvent(e) ? e.changedTouches[0] : e;
  }
  function getTarget(e) {
    return e.target || win.event.srcElement;
  }

  function isTouchEvent(e) {
    return e.type.indexOf('touch') >= 0;
  }

  function preventDefaultBehavior(e) {
    e.preventDefault ? e.preventDefault() : e.returnValue = false;
  }

  function getMoveDirectionExpected() {
    return getTouchDirection(toDegree(lastPosition.y - initPosition.y, lastPosition.x - initPosition.x), swipeAngle) === options.axis;
  }

  function onPanStart(e) {
    if (running) {
      if (preventActionWhenRunning) {
        return;
      } else {
        onTransitionEnd();
      }
    }

    if (autoplay && animating) {
      stopAutoplayTimer();
    }

    panStart = true;
    if (rafIndex) {
      caf(rafIndex);
      rafIndex = null;
    }

    var $ = getEvent(e);
    events.emit(isTouchEvent(e) ? 'touchStart' : 'dragStart', info(e));

    if (!isTouchEvent(e) && ['img', 'a'].indexOf(getLowerCaseNodeName(getTarget(e))) >= 0) {
      preventDefaultBehavior(e);
    }

    lastPosition.x = initPosition.x = $.clientX;
    lastPosition.y = initPosition.y = $.clientY;
    if (carousel) {
      translateInit = parseFloat(container.style[transformAttr].replace(transformPrefix, ''));
      resetDuration(container, '0s');
    }
  }

  function onPanMove(e) {
    if (panStart) {
      var $ = getEvent(e);
      lastPosition.x = $.clientX;
      lastPosition.y = $.clientY;

      if (carousel) {
        if (!rafIndex) {
          rafIndex = raf(function () {
            panUpdate(e);
          });
        }
      } else {
        if (moveDirectionExpected === '?') {
          moveDirectionExpected = getMoveDirectionExpected();
        }
        if (moveDirectionExpected) {
          preventScroll = true;
        }
      }

      if (preventScroll) {
        e.preventDefault();
      }
    }
  }

  function panUpdate(e) {
    if (!moveDirectionExpected) {
      panStart = false;
      return;
    }
    caf(rafIndex);
    if (panStart) {
      rafIndex = raf(function () {
        panUpdate(e);
      });
    }

    if (moveDirectionExpected === '?') {
      moveDirectionExpected = getMoveDirectionExpected();
    }
    if (moveDirectionExpected) {
      if (!preventScroll && isTouchEvent(e)) {
        preventScroll = true;
      }

      try {
        if (e.type) {
          events.emit(isTouchEvent(e) ? 'touchMove' : 'dragMove', info(e));
        }
      } catch (err) {}

      var x = translateInit,
          dist = getDist(lastPosition, initPosition);
      if (!horizontal || fixedWidth || autoWidth) {
        x += dist;
        x += 'px';
      } else {
        var percentageX = TRANSFORM ? dist * items * 100 / ((viewport + gutter) * slideCountNew) : dist * 100 / (viewport + gutter);
        x += percentageX;
        x += '%';
      }

      container.style[transformAttr] = transformPrefix + x + transformPostfix;
    }
  }

  function onPanEnd(e) {
    if (panStart) {
      if (rafIndex) {
        caf(rafIndex);
        rafIndex = null;
      }
      if (carousel) {
        resetDuration(container, '');
      }
      panStart = false;

      var $ = getEvent(e);
      lastPosition.x = $.clientX;
      lastPosition.y = $.clientY;
      var dist = getDist(lastPosition, initPosition);

      if (Math.abs(dist)) {
        // drag vs click
        if (!isTouchEvent(e)) {
          // prevent "click"
          var target = getTarget(e);
          addEvents(target, { 'click': function preventClick(e) {
              preventDefaultBehavior(e);
              removeEvents(target, { 'click': preventClick });
            } });
        }

        if (carousel) {
          rafIndex = raf(function () {
            if (horizontal && !autoWidth) {
              var indexMoved = -dist * items / (viewport + gutter);
              indexMoved = dist > 0 ? Math.floor(indexMoved) : Math.ceil(indexMoved);
              index += indexMoved;
            } else {
              var moved = -(translateInit + dist);
              if (moved <= 0) {
                index = indexMin;
              } else if (moved >= slidePositions[slideCountNew - 1]) {
                index = indexMax;
              } else {
                var i = 0;
                while (i < slideCountNew && moved >= slidePositions[i]) {
                  index = i;
                  if (moved > slidePositions[i] && dist < 0) {
                    index += 1;
                  }
                  i++;
                }
              }
            }

            render(e, dist);
            events.emit(isTouchEvent(e) ? 'touchEnd' : 'dragEnd', info(e));
          });
        } else {
          if (moveDirectionExpected) {
            onControlsClick(e, dist > 0 ? -1 : 1);
          }
        }
      }
    }

    // reset
    if (options.preventScrollOnTouch === 'auto') {
      preventScroll = false;
    }
    if (swipeAngle) {
      moveDirectionExpected = '?';
    }
    if (autoplay && !animating) {
      setAutoplayTimer();
    }
  }

  // === RESIZE FUNCTIONS === //
  // (slidePositions, index, items) => vertical_conentWrapper.height
  function updateContentWrapperHeight() {
    var wp = middleWrapper ? middleWrapper : innerWrapper;
    wp.style.height = slidePositions[index + items] - slidePositions[index] + 'px';
  }

  function getPages() {
    var rough = fixedWidth ? (fixedWidth + gutter) * slideCount / viewport : slideCount / items;
    return Math.min(Math.ceil(rough), slideCount);
  }

  /*
   * 1. update visible nav items list
   * 2. add "hidden" attributes to previous visible nav items
   * 3. remove "hidden" attrubutes to new visible nav items
   */
  function updateNavVisibility() {
    if (!nav || navAsThumbnails) {
      return;
    }

    if (pages !== pagesCached) {
      var min = pagesCached,
          max = pages,
          fn = showElement;

      if (pagesCached > pages) {
        min = pages;
        max = pagesCached;
        fn = hideElement;
      }

      while (min < max) {
        fn(navItems[min]);
        min++;
      }

      // cache pages
      pagesCached = pages;
    }
  }

  function info(e) {
    return {
      container: container,
      slideItems: slideItems,
      navContainer: navContainer,
      navItems: navItems,
      controlsContainer: controlsContainer,
      hasControls: hasControls,
      prevButton: prevButton,
      nextButton: nextButton,
      items: items,
      slideBy: slideBy,
      cloneCount: cloneCount,
      slideCount: slideCount,
      slideCountNew: slideCountNew,
      index: index,
      indexCached: indexCached,
      displayIndex: getCurrentSlide(),
      navCurrentIndex: navCurrentIndex,
      navCurrentIndexCached: navCurrentIndexCached,
      pages: pages,
      pagesCached: pagesCached,
      sheet: sheet,
      isOn: isOn,
      event: e || {}
    };
  }

  return {
    version: '2.9.1',
    getInfo: info,
    events: events,
    goTo: goTo,
    play: play,
    pause: pause,
    isOn: isOn,
    updateSliderHeight: updateInnerWrapperHeight,
    refresh: initSliderTransform,
    destroy: destroy,
    rebuild: function rebuild() {
      return tns(extend(options, optionsElements));
    }
  };
};

var FeaturedCarouselComponent = function (_Component) {
    _inherits(FeaturedCarouselComponent, _Component);

    _createClass(FeaturedCarouselComponent, null, [{
        key: 'selector',
        value: function selector() {
            return '.featured-carousel';
        }
    }]);

    function FeaturedCarouselComponent(opts) {
        var _ret;

        _classCallCheck(this, FeaturedCarouselComponent);

        var _this = _possibleConstructorReturn(this, (FeaturedCarouselComponent.__proto__ || Object.getPrototypeOf(FeaturedCarouselComponent)).call(this, opts));

        if (!_this.getSlider() || _this.getSlider().childElementCount < 2) return _ret = _this.el.setAttribute('data-slider-disabled', true), _possibleConstructorReturn(_this, _ret);

        _this.render();

        _this.slider = tns({
            autoWidth: true,
            container: _this.getSlider(),
            controlsContainer: _this.getControls(),
            items: 1,
            slideBy: 'page',
            loop: false,
            lazyload: true,
            mouseDrag: true,
            nav: false,
            speed: 400,
            preventScrollOnTouch: 'auto',
            onInit: function onInit() {
                return setTimeout(function () {
                    _this.listen();
                    _this.render();
                }, 17);
            },
            responsive: {
                768: {
                    items: 3,
                    slideBy: 3
                }
            }
        });
        return _this;
    }

    _createClass(FeaturedCarouselComponent, [{
        key: 'listen',
        value: function listen() {
            var _this2 = this;

            this.on('resize', function () {
                return _this2.render();
            });
            this.on('resize:debounced', function () {
                return _this2.render();
            });

            this.slider.events.on('indexChanged', function () {
                return setTimeout(function () {
                    return _this2.render();
                }, 17);
            });

            Array.from(this.slider.getInfo().slideItems).forEach(function (el, idx) {
                if (el.querySelector('a')) el.querySelector('a').onfocus = function () {
                    _this2.getSlider().scrollLeft = 0;
                };
            });
        }
    }, {
        key: 'onResizeDebounced',
        value: function onResizeDebounced() {
            return this.slider && 'goTo' in this.slider ? this.slider.goTo(this.slider.getInfo().index) : null;
        }
    }, {
        key: 'getControls',
        value: function getControls() {
            return this.el.querySelector('.featured-carousel-controls-items');
        }
    }, {
        key: 'getControlsBtn',
        value: function getControlsBtn(dir) {
            dir = dir === 'prev' || dir === 'next' ? dir : 'next';

            return this.el.querySelector('.featured-carousel-controls a[data-controls="' + dir + '"]');
        }
    }, {
        key: 'getControlsStatus',
        value: function getControlsStatus() {
            return this.el.querySelector('.featured-carousel-controls-status');
        }
    }, {
        key: 'getSlider',
        value: function getSlider() {
            return this.el.querySelector('.featured-carousel-slider');
        }
    }, {
        key: 'isControlsBtnDisabled',
        value: function isControlsBtnDisabled(dir) {
            return this.getControlsBtn(dir).getAttribute('aria-disabled') === 'true';
        }
    }, {
        key: 'renderDisplayIndex',
        value: function renderDisplayIndex(idx) {
            if (!('getInfo' in (this.slider || {})) || !this.getControlsStatus() || !idx) return;

            var info = this.slider.getInfo();

            var slideByCount = info.slideBy === 1 ? info.slideCount : Math.ceil(info.slideCount / info.slideBy);

            this.getControlsStatus().innerText = idx + '/' + slideByCount;
        }
    }, {
        key: 'render',
        value: function render() {
            if (!('getInfo' in (this.slider || {}))) return;

            var info = this.slider.getInfo();
            var isLastItemsVisible = info.slideCount - info.index <= info.slideBy;

            var displayIndex = 1;

            if (isLastItemsVisible) displayIndex = Math.ceil(info.slideCount / info.slideBy);

            if (info.slideBy === 1) displayIndex = info.displayIndex;

            // Enforce correct slideBy in edge-cases of tns
            if (info.slideBy > 1) {
                var slideIndexes = [0];

                while (slideIndexes[slideIndexes.length - 1] < info.slideCount - info.slideBy) {
                    slideIndexes.push(slideIndexes.length * info.slideBy);
                }var slideByIndex = isLastItemsVisible ? slideIndexes[slideIndexes.length - 1] : -1;

                if (slideByIndex < 0) for (var i = 0; i < slideIndexes.length; i++) {
                    if (info.index >= slideIndexes[i] && info.index < slideIndexes[i + 1]) slideByIndex = slideIndexes[i];
                }if (!isLastItemsVisible && slideIndexes.indexOf(slideByIndex) >= 0) displayIndex = slideIndexes.indexOf(slideByIndex) + 1;

                if (slideIndexes.indexOf(info.index) < 0 && slideByIndex >= 0) {
                    this.renderDisplayIndex(displayIndex);
                    return this.slider.goTo(slideByIndex);
                }
            }

            this.renderDisplayIndex(displayIndex

            // Override tns when its translate X val is incorrect
            );var inlineX = Number(this.getSlider().style.transform.replace(/\w+\((-*\d{1,}(?:\.\d{1,})*).+/, '$1'));

            var itemWidth = Number(info.slideItems[0].getBoundingClientRect().width).toFixed(2);

            var x = this.getControls().style.display === 'none' ? 0 : (isLastItemsVisible ? info.slideCount - info.slideBy : info.index) * -itemWidth;

            if (inlineX !== x) this.getSlider().style.transform = 'translate3d(' + x + 'px, 0, 0)';

            // Override tns when next btn disabled state is incorrect
            if (this.getControlsBtn('next')) this.getControlsBtn('next').setAttribute('aria-disabled', isLastItemsVisible);
        }
    }]);

    return FeaturedCarouselComponent;
}(Component);

var FormValidatorComponent = function (_Component) {
    _inherits(FormValidatorComponent, _Component);

    _createClass(FormValidatorComponent, null, [{
        key: 'selector',
        value: function selector() {
            return 'form.form-validator';
        }
    }]);

    function FormValidatorComponent(opts) {
        _classCallCheck(this, FormValidatorComponent);

        var _this = _possibleConstructorReturn(this, (FormValidatorComponent.__proto__ || Object.getPrototypeOf(FormValidatorComponent)).call(this, opts));

        if (!_this.el) return _possibleConstructorReturn(_this);

        _this.render();
        _this.listen();
        return _this;
    }

    _createClass(FormValidatorComponent, [{
        key: 'listen',
        value: function listen() {
            var _this2 = this;

            this.el.addEventListener('valid', function (e) {
                return _this2.onFormValid(e);
            });

            this.el.addEventListener('invalid', function (e) {
                return _this2.onFormInvalid(e);
            });

            this.getFormValidationEls().forEach(function (el) {
                el.addEventListener('invalid', function (e) {
                    e.preventDefault();
                    e.stopPropagation();

                    _this2.onForceDirty();
                });

                el.addEventListener('blur', function (e) {
                    return _this2.onDirty(e);
                });

                el.addEventListener('keyup', function () {
                    return _this2.el.classList.contains('dirty') ? _this2.onDirty() : null;
                });
            });

            this.getSubmitButton().addEventListener('focus', function () {
                return _this2.onDirty();
            });

            this.getSubmitButton().addEventListener('click', function () {
                return _this2.onForceDirty();
            });

            this.el.addEventListener('submit', function (e) {
                return _this2.onSubmit(e);
            });
        }
    }, {
        key: 'onFormValid',
        value: function onFormValid(e) {
            if (this.el.classList.contains('invalid')) this.el.classList.remove('invalid');
        }
    }, {
        key: 'onFormInvalid',
        value: function onFormInvalid(e) {
            e.preventDefault();
            e.stopPropagation();

            if (this.getBrowser('name').match(/(ie|edge)/)) this.el.classList.add('invalid');
        }
    }, {
        key: 'onForceDirty',
        value: function onForceDirty() {
            this.getFormValidationEls().forEach(function (el) {
                return el.classList.add('dirty');
            });

            this.onDirty();
        }
    }, {
        key: 'onDirty',
        value: function onDirty(e) {
            this.el.classList.add('dirty');

            if (!this.isFormValid() && this.getBrowser('name').match(/(ie|edge)/)) this.el.classList.add('invalid');

            if (e && e.target) e.target.classList.add('dirty');

            this.getFormValidationEls().forEach(function (el) {
                el.classList[el.validity.valid ? 'remove' : 'add']('invalid');
            });
        }
    }, {
        key: 'onSubmit',
        value: function onSubmit(e) {
            var classListMethod = void 0;

            if (!this.isFormValid() && !this.el.classList.contains('invalid')) classListMethod = 'add';

            if (this.isFormValid() && this.el.classList.contains('invalid')) classListMethod = 'remove';

            if (classListMethod) this.el.classList[classListMethod]('invalid');

            this.onForceDirty();
        }
    }, {
        key: 'getFormEls',
        value: function getFormEls() {
            return Array.from(this.el.querySelectorAll('input, textarea, select'));
        }
    }, {
        key: 'getFormValidationEls',
        value: function getFormValidationEls() {
            return this.getFormEls().filter(function (el) {
                return el.type !== 'hidden' && (el.pattern || el.required);
            });
        }
    }, {
        key: 'getSubmitButton',
        value: function getSubmitButton() {
            return this.el.querySelector('[type="submit"]');
        }
    }, {
        key: 'isFormValid',
        value: function isFormValid() {
            return !this.getFormValidationEls().some(function (el) {
                return !el.validity.valid;
            });
        }
    }, {
        key: 'render',
        value: function render() {
            Array.from(this.getFormEls()).filter(function (el) {
                return el.type !== 'hidden';
            }).forEach(function (el) {
                if ('autocapitalize' in el && el.type !== 'text') el.setAttribute('autocapitalize', 'none');

                if ('autocomplete' in el && el.autocomplete !== 'off') el.setAttribute('autocomplete', el.type || true);
            });
        }
    }]);

    return FormValidatorComponent;
}(Component);

var GlobalHeaderComponent = function (_Component) {
    _inherits(GlobalHeaderComponent, _Component);

    _createClass(GlobalHeaderComponent, null, [{
        key: 'selector',
        value: function selector() {
            return '#global-header';
        }
    }]);

    function GlobalHeaderComponent(opts) {
        _classCallCheck(this, GlobalHeaderComponent);

        var _this = _possibleConstructorReturn(this, (GlobalHeaderComponent.__proto__ || Object.getPrototypeOf(GlobalHeaderComponent)).call(this, opts));

        _this.addFeaturesToClassName();

        _this.onEditorialNavBarBottomDrawerToggleClick = _this.onEditorialNavBarBottomDrawerToggleClick.bind(_this);

        _this.onEditorialNavBarBottomDrawerToggleKeydown = _this.onEditorialNavBarBottomDrawerToggleKeydown.bind(_this);

        _this.onPrimaryMenuToggleClick = _this.onPrimaryMenuToggleClick.bind(_this);

        _this.onPrimaryMenuToggleKeydown = _this.onPrimaryMenuToggleKeydown.bind(_this);

        _this.onPrimaryMenuSubnavToggleClick = _this.onPrimaryMenuSubnavToggleClick.bind(_this);

        _this.onPrimaryMenuSubnavToggleKeydown = _this.onPrimaryMenuSubnavToggleKeydown.bind(_this);

        _this.onTabKeydownSmallViewports = _this.onTabKeydownSmallViewports.bind(_this);

        _this.onTabKeydownLargeViewports = _this.onTabKeydownLargeViewports.bind(_this);

        _this.listen();
        _this.render();
        return _this;
    }

    _createClass(GlobalHeaderComponent, [{
        key: 'listen',
        value: function listen() {
            var _this2 = this;

            this.getEditorialNavBarBottomDrawerToggles().forEach(function (el) {
                el.addEventListener('click', _this2.onEditorialNavBarBottomDrawerToggleClick);

                el.addEventListener('keydown', _this2.onEditorialNavBarBottomDrawerToggleKeydown);
            });

            this.getPrimaryMenuToggles().forEach(function (el) {
                el.addEventListener('click', _this2.onPrimaryMenuToggleClick);

                el.addEventListener('keydown', _this2.onPrimaryMenuToggleKeydown);
            });

            this.getPrimaryMenuSubnavToggles().forEach(function (el) {
                el.addEventListener('keydown', _this2.onPrimaryMenuSubnavToggleKeydown);

                el.addEventListener('click', _this2.onPrimaryMenuSubnavToggleClick);
            });

            this.on('resize', function () {
                return _this2.render();
            });

            this.el.addEventListener('keydown', this.onTabKeydownSmallViewports);

            if (RootComponent.el || this.el.querySelector('.global-header-menu-primary-submenu-active')) RootComponent.el.addEventListener('keydown', this.onTabKeydownLargeViewports);
        }
    }, {
        key: 'onTabKeydownSmallViewports',
        value: function onTabKeydownSmallViewports(e) {
            if (!(e.keyIdentifier || e.key || '').match(/tab/i) || !this.isPrimaryMenuExpanded()) return;

            var activeEl = void 0;

            var menu = this.el.querySelector('.global-header-menu');

            if (!menu) return;

            var els = Tabbable(menu);

            if (e.shiftKey && els.indexOf(e.target) === 0) activeEl = els[els.length - 1];

            if (!e.shiftKey && els.indexOf(e.target) === els.length - 1) activeEl = els[0];

            if (!activeEl) return;

            e.preventDefault();
            e.stopPropagation();

            activeEl.focus();
        }
    }, {
        key: 'onTabKeydownLargeViewports',
        value: function onTabKeydownLargeViewports(e) {
            if (!(e.keyIdentifier || e.key || '').match(/tab/i) || this.isEditorialNavBarBottomVisible()) return;

            var els = Tabbable(this.el);

            var isComponentEl = els.indexOf(e.target) >= 0;

            var rootEls = Tabbable(RootComponent.el) || [];

            var activeSubmenu = this.el.querySelector('.global-header-menu-primary-submenu-active');

            var activeSubmenuEls = activeSubmenu ? Tabbable(activeSubmenu) : [];

            var activeSubmenuFirstEl = activeSubmenuEls[0];

            var activeSubmenuLastEl = activeSubmenuEls[activeSubmenuEls.length - 1];

            var ariaOwnsAnchor = this.el.querySelector('#global-header-menu-primary li[aria-owns] a');

            var activeEl = void 0;

            if (!activeSubmenuEls.length || !isComponentEl && (!e.shiftKey || activeSubmenuLastEl !== rootEls[rootEls.indexOf(e.target) - 1])) return;

            if (isComponentEl && !e.shiftKey && activeSubmenuEls.length && els[els.indexOf(e.target) + 1] === activeSubmenuFirstEl && e.target !== ariaOwnsAnchor) activeEl = rootEls[rootEls.indexOf(activeSubmenuLastEl) + 1] || rootEls[0];else if (!isComponentEl && e.shiftKey && activeSubmenuEls.length && activeSubmenuLastEl === rootEls[rootEls.indexOf(e.target) - 1]) activeEl = els[els.indexOf(activeSubmenuFirstEl) - 1];else if (!e.shiftKey && ariaOwnsAnchor && activeSubmenuEls.indexOf(e.target) === activeSubmenuEls.length - 1) activeEl = els[els.indexOf(ariaOwnsAnchor) + 1];else if (e.shiftKey && ariaOwnsAnchor && els[els.indexOf(e.target) - 1] === ariaOwnsAnchor && activeSubmenuEls.length) activeEl = activeSubmenuEls[activeSubmenuEls.length - 1];else if (e.shiftKey && ariaOwnsAnchor && activeSubmenuEls.indexOf(e.target) === 0) activeEl = ariaOwnsAnchor;else if (!e.shiftKey && e.target.parentNode && e.target.parentNode.parentNode && e.target.parentNode.parentNode.getAttribute('aria-owns')) {
                var submenu = this.el.querySelector('#' + e.target.parentNode.parentNode.getAttribute('aria-owns'));

                if (!submenu) return;

                var submenuEls = Tabbable(submenu);

                if (submenuEls[0]) activeEl = submenuEls[0];
            }

            if (!activeEl) return;

            e.preventDefault();
            e.stopPropagation();

            activeEl.focus();
        }
    }, {
        key: 'onEditorialNavBarBottomDrawerToggleClick',
        value: function onEditorialNavBarBottomDrawerToggleClick(e) {
            this.props.editorialNavbarDrawerExpanded = !(this.props.editorialNavbarDrawerExpanded === 'true');

            this.render();
        }
    }, {
        key: 'onEditorialNavBarBottomDrawerToggleKeydown',
        value: function onEditorialNavBarBottomDrawerToggleKeydown(e) {
            if (e.keyCode === 32 || (e.keyIdentifier || e.key || '').match(/^(enter)$/i)) this.onEditorialNavBarBottomDrawerToggleClick(e);
        }
    }, {
        key: 'onPrimaryMenuToggleClick',
        value: function onPrimaryMenuToggleClick(e) {
            this.props.primaryMenuExpanded = !(this.props.primaryMenuExpanded === 'true');

            this.render();
        }
    }, {
        key: 'onPrimaryMenuToggleKeydown',
        value: function onPrimaryMenuToggleKeydown(e) {
            if (e.keyCode === 32 || (e.keyIdentifier || e.key || '').match(/^(enter)$/i)) this.onPrimaryMenuToggleClick(e);
        }
    }, {
        key: 'onPrimaryMenuSubnavToggleClick',
        value: function onPrimaryMenuSubnavToggleClick(e) {
            var target = e.target.getAttribute('aria-controls') ? e.target : e.target.parentNode.getAttribute('aria-controls') ? e.target.parentNode : null;
            var controls = target && target.getAttribute('aria-controls') ? this.el.querySelector('#' + target.getAttribute('aria-controls')) : null;

            if (!controls) return;

            var isExpanded = target.getAttribute('aria-expanded') === 'true';

            target.setAttribute('aria-expanded', !isExpanded);
            controls.classList[isExpanded ? 'remove' : 'add']('active');

            this.renderPrimaryMenuSubnavToggles();
        }
    }, {
        key: 'onPrimaryMenuSubnavToggleKeydown',
        value: function onPrimaryMenuSubnavToggleKeydown(e) {
            if (e.keyCode === 32 || (e.keyIdentifier || e.key || '').match(/^(enter)$/i)) this.onPrimaryMenuSubnavToggleClick(e);
        }
    }, {
        key: 'isEditorialNavBarBottomVisible',
        value: function isEditorialNavBarBottomVisible() {
            return Boolean(this.getEditorialNavBarBottom() && this.getEditorialNavBarBottom().clientHeight);
        }
    }, {
        key: 'isEditorialNavBarBottomDisabled',
        value: function isEditorialNavBarBottomDisabled() {
            return this.getEditorialNavBarBottomDrawerHeight() > window.innerHeight;
        }
    }, {
        key: 'isPrimaryMenuExpanded',
        value: function isPrimaryMenuExpanded() {
            return this.isEditorialNavBarBottomVisible() && this.props.primaryMenuExpanded === 'true';
        }
    }, {
        key: 'getEditorialNavBarBottom',
        value: function getEditorialNavBarBottom() {
            return this.el.querySelector('#global-header-editorial-navbar-bottom');
        }
    }, {
        key: 'getEditorialNavBarBottomDrawer',
        value: function getEditorialNavBarBottomDrawer() {
            return this.el.querySelector('#global-header-editorial-navbar-drawer');
        }
    }, {
        key: 'getEditorialNavBarBottomDrawerHeight',
        value: function getEditorialNavBarBottomDrawerHeight() {
            return this.el.querySelector('.global-header-editorial-navbar-drawer-items').clientHeight;
        }
    }, {
        key: 'getEditorialNavBarBottomDrawerToggles',
        value: function getEditorialNavBarBottomDrawerToggles() {
            return Array.from(this.el.querySelectorAll('.global-header-editorial-navbar-drawer-toggle'));
        }
    }, {
        key: 'getPrimaryMenu',
        value: function getPrimaryMenu() {
            return this.el.querySelector('#global-header-menu-primary');
        }
    }, {
        key: 'getPrimaryMenuToggles',
        value: function getPrimaryMenuToggles() {
            return Array.from(this.el.querySelectorAll('.global-header-menu-primary-toggle'));
        }
    }, {
        key: 'getPrimaryMenuSubnavToggles',
        value: function getPrimaryMenuSubnavToggles() {
            return Array.from(this.el.querySelectorAll('.global-header-menu-primary-submenu-toggle'));
        }
    }, {
        key: 'getScrollOffset',
        value: function getScrollOffset() {
            return Number(((RootComponent || {}).props || {}).scrollOffset || 0);
        }
    }, {
        key: 'setSiblingAriaHiddenAttr',
        value: function setSiblingAriaHiddenAttr() {
            var _this3 = this;

            if (this.el.getAttribute('aria-hidden') === 'true') return;

            Array.from((RootComponent.el || {}).children).filter(function (el) {
                return !el.tagName.match(/script/i) && el !== _this3.el;
            }).forEach(function (el) {
                if (el.getAttribute('aria-hidden') && !_this3.isPrimaryMenuExpanded()) return el.id !== 'modal' ? el.removeAttribute('aria-hidden') : null;

                if (_this3.isPrimaryMenuExpanded() && el.getAttribute('aria-hidden') !== 'true') el.setAttribute('aria-hidden', true);
            });
        }
    }, {
        key: 'renderEditorialNavBarBottomDrawerToggles',
        value: function renderEditorialNavBarBottomDrawerToggles() {
            var _this4 = this;

            if (!this.getEditorialNavBarBottomDrawer()) return;

            this.getEditorialNavBarBottomDrawerToggles().forEach(function (el) {
                if (!el.getAttribute('tabindex')) el.setAttribute('tabindex', 0);

                if (el.getAttribute('aria-controls') !== (_this4.getEditorialNavBarBottomDrawer().id || '').replace(/^#/, '')) el.setAttribute('aria-controls', (_this4.getEditorialNavBarBottomDrawer().id || '').replace(/^#/, ''));

                if (el.getAttribute('aria-expanded') !== _this4.props.editorialNavbarDrawerExpanded) el.setAttribute('aria-expanded', _this4.props.editorialNavbarDrawerExpanded || false);

                var label = void 0;

                if (el.getAttribute('data-aria-label-collapsed') && _this4.props.editorialNavbarDrawerExpanded !== 'true') label = el.getAttribute('data-aria-label-collapsed');else if (el.getAttribute('data-aria-label-expanded') && _this4.props.editorialNavbarDrawerExpanded === 'true') label = el.getAttribute('data-aria-label-expanded');

                if (label) el.setAttribute('aria-label', label);
            });
        }
    }, {
        key: 'renderPrimaryMenuSubnavToggles',
        value: function renderPrimaryMenuSubnavToggles() {
            this.getPrimaryMenuSubnavToggles().forEach(function (el) {
                if (!el.getAttribute('tabindex')) el.setAttribute('tabindex', 0);

                var isExpanded = Boolean(el.getAttribute('aria-expanded'));

                var label = void 0;

                if (el.getAttribute('data-aria-label-collapsed') && !isExpanded) label = el.getAttribute('data-aria-label-collapsed');else if (el.getAttribute('data-aria-label-expanded') && isExpanded) label = el.getAttribute('data-aria-label-expanded');

                if (label) el.setAttribute('aria-label', label);
            });
        }
    }, {
        key: 'renderPrimaryMenuToggles',
        value: function renderPrimaryMenuToggles() {
            var _this5 = this;

            if (!this.getPrimaryMenu()) return;

            this.getPrimaryMenuToggles().forEach(function (el) {
                if (!el.getAttribute('tabindex')) el.setAttribute('tabindex', 0);

                if (el.getAttribute('aria-controls') !== (_this5.getPrimaryMenu().id || '').replace(/^#/, '')) el.setAttribute('aria-controls', (_this5.getPrimaryMenu().id || '').replace(/^#/, ''));

                if (el.getAttribute('aria-expanded') !== _this5.props.primaryMenuExpanded) el.setAttribute('aria-expanded', _this5.props.primaryMenuExpanded || false);

                var label = void 0;

                if (el.getAttribute('data-aria-label-collapsed') && _this5.props.primaryMenuExpanded !== 'true') label = el.getAttribute('data-aria-label-collapsed');else if (el.getAttribute('data-aria-label-expanded') && _this5.props.primaryMenuExpanded === 'true') label = el.getAttribute('data-aria-label-expanded');

                if (label) el.setAttribute('aria-label', label);
            });
        }
    }, {
        key: 'render',
        value: function render() {
            this.renderEditorialNavBarBottomDrawerToggles();
            this.renderPrimaryMenuSubnavToggles();
            this.renderPrimaryMenuToggles();
            this.setSiblingAriaHiddenAttr();

            if (this.props.editorialNavbarDrawerDisabled !== String(this.isEditorialNavBarBottomDisabled())) this.props.editorialNavbarDrawerDisabled = String(this.isEditorialNavBarBottomDisabled());

            if (this.isPrimaryMenuExpanded() && !document.documentElement.classList.contains('overflow-hidden')) RootComponent.emit('scroll:disable');else if (document.documentElement.classList.contains('overflow-hidden') && this.el.getAttribute('aria-hidden') !== 'true' && !this.isPrimaryMenuExpanded()) RootComponent.emit('scroll:enable');
        }
    }]);

    return GlobalHeaderComponent;
}(Component);

var HeroCarouselComponent = function (_Component) {
    _inherits(HeroCarouselComponent, _Component);

    _createClass(HeroCarouselComponent, null, [{
        key: 'selector',
        value: function selector() {
            return '.hero-carousel';
        }
    }]);

    function HeroCarouselComponent(opts) {
        _classCallCheck(this, HeroCarouselComponent);

        var _this = _possibleConstructorReturn(this, (HeroCarouselComponent.__proto__ || Object.getPrototypeOf(HeroCarouselComponent)).call(this, opts));

        _this.addFeatureTest({
            csspointerevents: function csspointerevents() {
                return _this.detectCSSProp('pointer-events');
            }
        });

        _this.addFeaturesToClassName();

        _this.speed = _this.getFeature('touch') ? 400 : 600;

        _this.render();

        _this.slider = tns({
            arrowKeys: false,
            autoHeight: true,
            autoplay: !_this.getFeature('touch'),
            autoplayButtonOutput: false,
            autoplayTimeout: 10000,
            container: _this.getSlider(),
            controls: false,
            items: 1,
            slideBy: 'page',
            loop: false,
            rewind: true,
            mouseDrag: true,
            navContainer: _this.getNav(),
            speed: _this.speed,
            preventScrollOnTouch: 'auto',
            onInit: function onInit() {
                return setTimeout(function () {
                    _this.listen();
                    _this.render();
                }, 17);
            }
        });
        return _this;
    }

    _createClass(HeroCarouselComponent, [{
        key: 'listen',
        value: function listen() {
            var _this2 = this;

            this.on('resize', function () {
                return _this2.render();
            });
            this.on('resize:debounced', function () {
                return _this2.render();
            });

            document.body.addEventListener('keydown', function (e) {
                return _this2.onDocumentKeydown(e);
            });

            if (!this.getFeature('touch')) this.getNavItems().forEach(function (el) {
                el.addEventListener('click', function () {
                    return _this2.slider.pause();
                });
            });

            this.slider.events.on('indexChanged', function () {
                return setTimeout(function () {
                    return _this2.render();
                }, 17);
            });

            if (this.getFeature('touch')) return;

            this.getSlider().addEventListener('mouseover', function () {
                return _this2.onMouseOver();
            });

            this.getSlider().addEventListener('mouseout', function () {
                return _this2.onMouseOut();
            });
        }
    }, {
        key: 'onDocumentKeydown',
        value: function onDocumentKeydown(e) {
            var _this3 = this;

            if (!(e.keyIdentifier || e.key || '').match(/tab/i)) return;

            var activeEl = void 0;

            var els = Tabbable(document.body);

            if (e.shiftKey) activeEl = els.indexOf(e.target) === 0 ? els[els.length - 1] : els[els.indexOf(e.target) - 1];else activeEl = els.indexOf(e.target) === els.length - 1 ? els[0] : els[els.indexOf(e.target) + 1];

            if (!activeEl) return;

            Array.from(this.slider.getInfo().slideItems).forEach(function (el, idx) {
                if (Tabbable(el).indexOf(activeEl) >= 0 && _this3.slider.getInfo().index !== idx) {
                    e.preventDefault();
                    e.stopPropagation();

                    _this3.slider.pause();
                    _this3.slider.goTo(idx);

                    setTimeout(function () {
                        return _this3.slider.getInfo().index === idx ? activeEl.focus() : null;
                    }, _this3.speed / 2);
                }
            });
        }
    }, {
        key: 'onMouseOver',
        value: function onMouseOver() {
            if (this.el.getAttribute('data-mouseover')) return;

            this.el.setAttribute('data-mouseover', true);
            this.slider.pause();
        }
    }, {
        key: 'onMouseOut',
        value: function onMouseOut() {
            this.el.removeAttribute('data-mouseover');
        }
    }, {
        key: 'getSlider',
        value: function getSlider() {
            return this.el.querySelector('.hero-carousel-slider');
        }
    }, {
        key: 'getSliderItems',
        value: function getSliderItems() {
            return Array.from(this.el.querySelectorAll('.hero-carousel-slider-item'));
        }
    }, {
        key: 'getNav',
        value: function getNav() {
            return this.el.querySelector('.hero-carousel-slider-nav');
        }
    }, {
        key: 'getNavItems',
        value: function getNavItems() {
            return Array.from(this.el.querySelectorAll('.hero-carousel-slider-nav-item'));
        }
    }, {
        key: 'setSliderHeight',
        value: function setSliderHeight() {
            this.innerHeight = this.getFeature('touch') ? this.innerHeight || window.innerHeight : window.innerHeight;

            var offsetHeight = ((this.getNav() || {}).clientHeight || 0) + ((document.body.querySelector('#global-header') || {}).clientHeight || 0) + ((document.body.querySelector('#global-header-editorial-navbar-bottom') || {}).clientHeight || 0) + ((document.body.querySelector('#wpadminbar') || {}).clientHeight || 0);

            var maxMinHeight = 1000;
            var minHeight = (this.innerHeight - offsetHeight > maxMinHeight ? maxMinHeight : this.innerHeight - offsetHeight) + 'px';

            if (this.getSlider().style.minHeight !== minHeight) this.getSlider().style.minHeight = minHeight;
        }
    }, {
        key: 'isLargeViewport',
        value: function isLargeViewport() {
            return this.el.clientWidth >= 1024;
        }
    }, {
        key: 'render',
        value: function render() {
            var _this4 = this;

            if (!this.el.classList.contains('show')) {
                this.el.style.transition = 'opacity ' + this.speed * 0.75 + 'ms';
                this.el.classList.add('show');
            }

            if (!this.getSlider()) return;

            this.getNavItems().forEach(function (el) {
                return el.setAttribute('tabindex', 0);
            });

            this.getSliderItems().forEach(function (el) {
                if (el.style.width !== window.innerWidth + 'px') el.style.width = window.innerWidth + 'px';

                if (el.style.maxWidth !== el.style.width) el.style.maxWidth = el.style.width;

                var content = el.querySelector('.hero-carousel-slider-item-content');

                if (!content.style.transitionDuration) content.style.transitionDuration = _this4.speed * 2 + 'ms';

                if (!content.style.transitionDelay) content.style.transitionDelay = _this4.speed + 'ms';

                var index = el.querySelector('.hero-carousel-slider-item-index');

                if (!index.style.transitionDuration) index.style.transitionDuration = _this4.speed + 'ms';

                if (!index.style.transitionDelay) index.style.transitionDelay = _this4.speed * 2 + 'ms';
            });

            this.setSliderHeight();

            if ('getInfo' in (this.slider || {})) {
                var info = this.slider.getInfo();

                var xOffset = (window.innerWidth >= 1024 ? (info.index || 0) * -5 : 0) + 'px';

                var parentOffsetStyle = 'transition: transform ' + this.speed + 'ms; transform: translate3d(' + xOffset + ', 0px, 0px);';

                if (this.getSlider().parentNode.getAttribute('style') !== parentOffsetStyle) this.getSlider().parentNode.setAttribute('style', parentOffsetStyle);
            }
        }
    }]);

    return HeroCarouselComponent;
}(Component);

var HashAnchorComponent = function (_Component) {
    _inherits(HashAnchorComponent, _Component);

    _createClass(HashAnchorComponent, null, [{
        key: 'selector',
        value: function selector() {
            return 'a[href^="#"], a[href^="' + window.location.href.replace(window.location.hash, '').replace('?' + window.location.search) + '#"]';
        }
    }]);

    function HashAnchorComponent(opts) {
        _classCallCheck(this, HashAnchorComponent);

        var _this = _possibleConstructorReturn(this, (HashAnchorComponent.__proto__ || Object.getPrototypeOf(HashAnchorComponent)).call(this, opts));

        if (_this.el.getAttribute('href') === '#') {
            _this.el.setAttribute('data-rendered', false);
            return _possibleConstructorReturn(_this);
        }

        _this.onHashAnchorClick = _this.onHashAnchorClick.bind(_this);
        _this.onHashAnchorKeydown = _this.onHashAnchorKeydown.bind(_this);

        _this.render();
        _this.listen();
        return _this;
    }

    _createClass(HashAnchorComponent, [{
        key: 'listen',
        value: function listen() {
            this.el.addEventListener('click', this.onHashAnchorClick);
            this.el.addEventListener('keydown', this.onHashAnchorKeydown);
        }
    }, {
        key: 'onHashAnchorClick',
        value: function onHashAnchorClick(e) {
            e.preventDefault();
            e.stopPropagation();

            var targetId = this.el.getAttribute('href').replace(/(.+)(#.+)/, '$2');

            var target = targetId ? document.querySelector(targetId) : null;

            this.render(true);

            var isDisableScrollOnCollapse = this.getToggleEl() && this.el.getAttribute('data-disable-scroll-on-collapse') === 'true' && this.el.getAttribute('aria-expanded') === 'false';

            if (!target || (RootComponent.Scroller || {}).scrollingTo || isDisableScrollOnCollapse) return;

            this.el.blur();

            var top = target.getBoundingClientRect().top + (RootComponent.Scroller.state.get('scrollY') || 0) - this.getScrollOffset();

            Promise.resolve(this.getBrowser('name') === 'ios' ? window.scrollTo(0, top) : RootComponent.Scroller.scrollTo({ y: top })).then(function () {
                target.focus();

                if (target.getAttribute('tabindex') === '-1') target.blur();
            });
        }
    }, {
        key: 'onHashAnchorKeydown',
        value: function onHashAnchorKeydown(e) {
            if (e.keyCode === 32 || (e.keyIdentifier || e.key || '').match(/^(enter)$/i)) return this.onHashAnchorClick(e);
        }
    }, {
        key: 'isToggle',
        value: function isToggle() {
            return Boolean(this.el.getAttribute('aria-controls'));
        }
    }, {
        key: 'getScrollOffset',
        value: function getScrollOffset() {
            return Number(((RootComponent || {}).props || {}).scrollOffset || 0);
        }
    }, {
        key: 'getToggleEl',
        value: function getToggleEl() {
            return !this.isToggle() ? null : RootComponent.el.querySelector('#' + this.el.getAttribute('aria-controls'));
        }
    }, {
        key: 'render',
        value: function render() {
            var toggleState = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

            if (!this.getToggleEl()) return;

            if (toggleState) this.el.setAttribute('aria-expanded', this.el.getAttribute('aria-expanded') !== 'true');

            if (!this.el.getAttribute('tabindex')) this.el.setAttribute('tabindex', 0);

            var isExpanded = this.el.getAttribute('aria-expanded') === 'true';

            var label = void 0;

            if (this.el.getAttribute('data-aria-label-collapsed') && !isExpanded) label = this.el.getAttribute('data-aria-label-collapsed');else if (this.el.getAttribute('data-aria-label-expanded') && isExpanded) label = this.el.getAttribute('data-aria-label-expanded');

            if (label) this.el.setAttribute('aria-label', label);

            this.getToggleEl().setAttribute('data-expanded', isExpanded);
        }
    }]);

    return HashAnchorComponent;
}(Component);

var _toConsumableArray = (function (arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }return arr2;
  } else {
    return Array.from(arr);
  }
});

var MailchimpFormComponent = function (_Component) {
    _inherits(MailchimpFormComponent, _Component);

    _createClass(MailchimpFormComponent, null, [{
        key: 'selector',
        value: function selector() {
            return '.mailchimp-form';
        }
    }]);

    function MailchimpFormComponent(opts) {
        _classCallCheck(this, MailchimpFormComponent);

        var _this = _possibleConstructorReturn(this, (MailchimpFormComponent.__proto__ || Object.getPrototypeOf(MailchimpFormComponent)).call(this, opts));

        if (!_this.el || !_this.getForm()) return _possibleConstructorReturn(_this);

        window.fetchJsonp = fetchJsonp;

        _this.listen();
        return _this;
    }

    _createClass(MailchimpFormComponent, [{
        key: 'listen',
        value: function listen() {
            var _this2 = this;

            this.getForm().addEventListener('submit', function (e) {
                return _this2.onSubmit(e);
            });
        }
    }, {
        key: 'onSubmit',
        value: function onSubmit(e) {
            var _this3 = this;

            e.preventDefault();
            e.stopPropagation();

            if (this.getForm().classList.contains('invalid') || this.getForm().classList.contains('submitting')) return;

            this.getForm().classList.add('submitting');

            var epoch = new Date().getTime();

            var queryString = [].concat(_toConsumableArray(new window.FormData(this.getForm()).entries())).map(function (entry) {
                return entry.map(encodeURIComponent).join('=');
            }).join('&');

            var url = this.getForm().getAttribute('action') + '/form-post-json?' + queryString + '&_=' + epoch + '&c=jsonp_cb_' + epoch;

            fetchJsonp(url, { jsonpCallbackFunction: 'jsonp_cb_' + epoch }).then(function (res) {
                return res.json();
            }).then(function (json) {
                return {
                    msg: (json.msg || 'Unknown error has occurred').replace(/\s*<a.+?<\/a>/g, '').replace(/<.+?>/g, '').replace(/\s+/g, ' '),
                    result: json.result || 'error'
                };
            }).then(function (json) {
                var responseTextEl = _this3.getForm().querySelector('.form-validator-submit-msg-' + json.result + ' em');

                if (responseTextEl) responseTextEl.innerText = json.msg;

                _this3.getForm().classList.remove('submitting');

                _this3.getForm().classList.add(json.result);
            });
        }
    }, {
        key: 'getForm',
        value: function getForm() {
            return this.el.querySelector('form');
        }
    }, {
        key: 'getSubmitButton',
        value: function getSubmitButton() {
            return this.el.querySelector('[type="submit"]');
        }
    }]);

    return MailchimpFormComponent;
}(Component);

var MailchimpPopupComponent = function (_Component) {
    _inherits(MailchimpPopupComponent, _Component);

    _createClass(MailchimpPopupComponent, null, [{
        key: 'selector',
        value: function selector() {
            return '.mailchimp-popup';
        }
    }]);

    function MailchimpPopupComponent(opts) {
        _classCallCheck(this, MailchimpPopupComponent);

        var _this = _possibleConstructorReturn(this, (MailchimpPopupComponent.__proto__ || Object.getPrototypeOf(MailchimpPopupComponent)).call(this, opts));

        _this.addFeatureTest({
            sessionstorage: function sessionstorage() {
                return 'sessionStorage' in window;
            }
        });

        _this.storageKey = _this.constructor.selector() + '.' + window.location.hostname + '.dismissed';

        if (_this.getFeature('sessionstorage') && window.sessionStorage.getItem(_this.storageKey) || document.body.classList.contains('member-logged-in') || document.querySelector('.shop-cart') || document.querySelector('.shop-checkout')) return _possibleConstructorReturn(_this);

        _this.onWindowBlur = _this.onWindowBlur.bind(_this);
        _this.listen();
        return _this;
    }

    _createClass(MailchimpPopupComponent, [{
        key: 'listen',
        value: function listen() {
            window.addEventListener('blur', this.onWindowBlur);
            document.body.addEventListener('mouseout', this.onWindowBlur);
        }
    }, {
        key: 'onWindowBlur',
        value: function onWindowBlur(e) {
            if (e.type === 'mouseout' && e.toElement) return;

            window.removeEventListener('blur', this.onWindowBlur);
            document.body.removeEventListener('mouseout', this.onWindowBlur);

            if (this.getFeature('sessionstorage')) window.sessionStorage.setItem(this.storageKey, true);

            this.render();
        }
    }, {
        key: 'getModalTriggerBtn',
        value: function getModalTriggerBtn() {
            return this.el.querySelector('a[role="button"]');
        }
    }, {
        key: 'render',
        value: function render() {
            if (!this.getModalTriggerBtn()) return;

            this.getModalTriggerBtn().click();
        }
    }]);

    return MailchimpPopupComponent;
}(Component);

var ModalComponent = function (_Component) {
    _inherits(ModalComponent, _Component);

    _createClass(ModalComponent, null, [{
        key: 'selector',
        value: function selector() {
            return '#modal';
        }
    }]);

    function ModalComponent(opts) {
        _classCallCheck(this, ModalComponent);

        var _this = _possibleConstructorReturn(this, (ModalComponent.__proto__ || Object.getPrototypeOf(ModalComponent)).call(this, opts));

        _this.onKeydown = _this.onKeydown.bind(_this);

        _this.onModalBtnClick = _this.onModalBtnClick.bind(_this);
        _this.onModalBtnKeydown = _this.onModalBtnKeydown.bind(_this);

        _this.onModalCloseBtnClick = _this.onModalCloseBtnClick.bind(_this);
        _this.onModalCloseBtnKeydown = _this.onModalCloseBtnKeydown.bind(_this);

        _this.addFeaturesToClassName();
        _this.listen();
        return _this;
    }

    _createClass(ModalComponent, [{
        key: 'listen',
        value: function listen() {
            var _this2 = this;

            this.el.addEventListener('keydown', this.onKeydown);

            document.body.addEventListener('keydown', function (e) {
                return _this2.isModalActive() ? _this2.onKeydown(e) : null;
            });

            this.modalBtnsListener();

            this.getModalCloseBtns().forEach(function (el) {
                if (!el.getAttribute('tabindex')) el.setAttribute('tabindex', 0);

                el.addEventListener('click', _this2.onModalCloseBtnClick);
                el.addEventListener('keydown', _this2.onModalCloseBtnKeydown);
            });

            if (!(['MutationObserver'] in window)) return;

            var observer = new window.MutationObserver(function (mutations) {
                return mutations.filter(function (mutation) {
                    return mutation.addedNodes.length;
                }).forEach(function (mutation) {
                    return _this2.modalBtnsListener();
                });
            });

            observer.observe(RootComponent.el, {
                childList: true,
                subtree: true
            });
        }
    }, {
        key: 'modalBtnsListener',
        value: function modalBtnsListener() {
            var _this3 = this;

            this.getModalBtns().forEach(function (el) {
                if (el.getAttribute('data-modal-listener')) return;

                el.setAttribute('data-modal-listener', true);

                if (!el.getAttribute('tabindex')) el.setAttribute('tabindex', 0);

                el.addEventListener('click', _this3.onModalBtnClick);
                el.addEventListener('keydown', _this3.onModalBtnKeydown);
            });
        }
    }, {
        key: 'onKeydown',
        value: function onKeydown(e) {
            if (this.isModalActive() && (e.keyIdentifier || e.key || '').match(/escape/i)) {
                e.stopPropagation();
                return this.onModalCloseBtnClick();
            }

            if (!this.isModalActive() || !(e.keyIdentifier || e.key || '').match(/tab/i)) return;

            var activeEl = void 0;

            var els = Tabbable(this.el);

            if (e.shiftKey && els.indexOf(e.target) === 0) activeEl = els[els.length - 1];

            if (!e.shiftKey && els.indexOf(e.target) === els.length - 1) activeEl = els[0];

            if (!activeEl) return;

            e.preventDefault();
            e.stopPropagation();

            activeEl.focus();
        }
    }, {
        key: 'onModalBtnClick',
        value: function onModalBtnClick(e) {
            var target = e.target.getAttribute('data-modal') ? e.target : e.target.parentNode.getAttribute('data-modal') ? e.target.parentNode : e.target.parentNode.parentNode.getAttribute('data-modal') ? e.target.parentNode.parentNode : null;

            if (!target) return;

            this.render(target);
        }
    }, {
        key: 'onModalBtnKeydown',
        value: function onModalBtnKeydown(e) {
            if (e.keyCode === 32 || (e.keyIdentifier || e.key || '').match(/^(enter)$/i)) this.onModalBtnClick(e);
        }
    }, {
        key: 'onModalCloseBtnClick',
        value: function onModalCloseBtnClick(e) {
            this.close();
        }
    }, {
        key: 'onModalCloseBtnKeydown',
        value: function onModalCloseBtnKeydown(e) {
            if (e.keyCode === 32 || (e.keyIdentifier || e.key || '').match(/^(enter)$/i)) this.onModalCloseBtnClick(e);
        }
    }, {
        key: 'isModalActive',
        value: function isModalActive() {
            return this.el.getAttribute('aria-hidden') === 'false';
        }
    }, {
        key: 'getModalBtns',
        value: function getModalBtns() {
            return Array.from(document.querySelectorAll('[data-modal]:not([data-modal-listener])'));
        }
    }, {
        key: 'getModalCloseBtns',
        value: function getModalCloseBtns() {
            return Array.from(document.querySelectorAll('[data-modal-close]'));
        }
    }, {
        key: 'getModalContent',
        value: function getModalContent() {
            return this.el.querySelector('.modal-content');
        }
    }, {
        key: 'setSiblingAriaHiddenAttr',
        value: function setSiblingAriaHiddenAttr() {
            var _this4 = this;

            Array.from((RootComponent.el || {}).children).filter(function (el) {
                return !el.tagName.match(/script/i) && el !== _this4.el;
            }).forEach(function (el) {
                if (!_this4.isModalActive() && el.getAttribute('aria-hidden')) return el.removeAttribute('aria-hidden');

                if (_this4.isModalActive() && el.getAttribute('aria-hidden') !== 'true') el.setAttribute('aria-hidden', true);
            });
        }
    }, {
        key: 'close',
        value: function close() {
            this.el.setAttribute('aria-hidden', 'true');
            this.el.removeAttribute('data-modal-type');

            this.getModalContent().innerHTML = '';
            this.setSiblingAriaHiddenAttr();

            document.documentElement.removeAttribute('data-modal-active');

            if (document.documentElement.classList.contains('overflow-hidden')) RootComponent.emit('scroll:enable');

            var refocusEl = RootComponent.el.querySelector('[data-modal-refocus]');

            if (!refocusEl) return;

            refocusEl.focus();
            refocusEl.removeAttribute('data-modal-refocus');
        }
    }, {
        key: 'render',
        value: function render(target) {
            var content = target.getAttribute('data-modal-content') ? RootComponent.el.querySelector('#' + target.getAttribute('data-modal-content')) : null;

            if (!content || !this.getModalContent()) return;

            target.setAttribute('data-modal-refocus', true);

            content = content.cloneNode(true);
            content.id = content.id + '-modal-clone';

            Array.from(content.querySelectorAll('[data-rendered]')).forEach(function (el) {
                return el.removeAttribute('data-rendered');
            });

            Array.from(content.querySelectorAll('[id]')).forEach(function (el) {
                var id = el.id;
                el.id = el.id + '-modal-clone';
                content.innerHTML = content.innerHTML.replace(new RegExp('("|\')' + id + '("|\')', 'g'), '$1' + el.id + '$2');
            });

            if (!document.documentElement.classList.contains('overflow-hidden')) {
                document.documentElement.setAttribute('data-modal-active', true);
                RootComponent.emit('scroll:disable');
            }

            if (target.getAttribute('data-modal-type')) this.el.setAttribute('data-modal-type', target.getAttribute('data-modal-type'));

            this.getModalContent().innerHTML = '';

            this.getModalContent().appendChild(content);

            RootComponent.emit('render');

            this.el.setAttribute('aria-hidden', false);

            this.el.focus();
            this.el.blur();

            this.setSiblingAriaHiddenAttr();

            window.objectFitPolyfill();
        }
    }]);

    return ModalComponent;
}(Component);

var PhotoGalleryComponent = function (_Component) {
    _inherits(PhotoGalleryComponent, _Component);

    _createClass(PhotoGalleryComponent, null, [{
        key: 'selector',
        value: function selector() {
            return '.photo-gallery';
        }
    }]);

    function PhotoGalleryComponent(opts) {
        _classCallCheck(this, PhotoGalleryComponent);

        var _this = _possibleConstructorReturn(this, (PhotoGalleryComponent.__proto__ || Object.getPrototypeOf(PhotoGalleryComponent)).call(this, opts));

        _this.addFeaturesToClassName();
        return _this;
    }

    return PhotoGalleryComponent;
}(Component);

var QueryFilterComponent = function (_Component) {
    _inherits(QueryFilterComponent, _Component);

    _createClass(QueryFilterComponent, null, [{
        key: 'selector',
        value: function selector() {
            return '.query-filter';
        }
    }]);

    function QueryFilterComponent(opts) {
        _classCallCheck(this, QueryFilterComponent);

        var _this = _possibleConstructorReturn(this, (QueryFilterComponent.__proto__ || Object.getPrototypeOf(QueryFilterComponent)).call(this, opts));

        _this.listen();
        return _this;
    }

    _createClass(QueryFilterComponent, [{
        key: 'listen',
        value: function listen() {
            var _this2 = this;

            if (!this.getSelect()) return;

            this.getSelect().addEventListener('change', function () {
                return _this2.el.submit();
            });

            if (!this.getResetBtn()) return;

            this.getResetBtn().addEventListener('click', function () {
                _this2.getSelect().value = '';
            });
        }
    }, {
        key: 'getSelect',
        value: function getSelect() {
            return this.el.querySelector('select');
        }
    }, {
        key: 'getResetBtn',
        value: function getResetBtn() {
            return this.el.querySelector('.query-filter-reset-btn');
        }
    }]);

    return QueryFilterComponent;
}(Component);

var SelectComponent = function (_Component) {
    _inherits(SelectComponent, _Component);

    _createClass(SelectComponent, null, [{
        key: 'selector',
        value: function selector() {
            return '.select';
        }
    }]);

    function SelectComponent(opts) {
        _classCallCheck(this, SelectComponent);

        var _this = _possibleConstructorReturn(this, (SelectComponent.__proto__ || Object.getPrototypeOf(SelectComponent)).call(this, opts));

        if (!_this.el) return _possibleConstructorReturn(_this);

        if (!_this.getSelect() || _this.getSelect().multiple || _this.getFeature('touch')) return _possibleConstructorReturn(_this);

        _this.onDocumentClick = _this.onDocumentClick.bind(_this);
        _this.onBtnKeydown = _this.onBtnKeydown.bind(_this);
        _this.onBtnClick = _this.onBtnClick.bind(_this);

        _this.onListboxKeydown = _this.onListboxKeydown.bind(_this);
        _this.onListboxClick = _this.onListboxClick.bind(_this);

        _this.render();
        _this.listen();
        return _this;
    }

    _createClass(SelectComponent, [{
        key: 'listen',
        value: function listen() {
            var _this2 = this;

            if (!this.getSelect() || !this.getSelectBtn() || !this.getSelectOptionsList()) return;

            this.getSelectBtn().onclick = this.onBtnClick;
            this.getSelectBtn().onkeydown = this.onBtnKeydown;

            this.getSelectOptionsList().onkeydown = this.onListboxKeydown;
            this.getSelectOptionsList().onclick = this.onListboxClick;
            this.getSelectOptionsList().onfocus = this.onListboxFocus;

            document.body.addEventListener('click', this.onDocumentClick, false);

            if (!('MutationObserver' in window)) return;

            this.observer = new window.MutationObserver(function (mutations) {
                return _this2.renderOptionsList();
            });

            this.observer.observe(this.getSelect(), { childList: true });
        }
    }, {
        key: 'onBtnClick',
        value: function onBtnClick(e) {
            if (this.getSelect().disabled) return this.el.classList.add('disabled');

            this.el.classList.remove('disabled');
            this.el.classList.toggle('active');

            if (!this.el.classList.contains('active')) return;

            this.getSelectOptionsList().focus();

            if ((e.keyIdentifier || e.key || '').match(/down/i)) this.setSelectedOption();
        }
    }, {
        key: 'onBtnKeydown',
        value: function onBtnKeydown(e) {
            if (e.which === 27) {
                this.el.classList.remove('active');
                return this.getSelectBtn().focus();
            }

            var key = e.keyIdentifier || e.key || '';

            if (e.keyCode === 27 || key.match(/enter/i) || key.match(/(down|up)/i) && !this.el.classList.contains('active')) this.onBtnClick(e);
        }
    }, {
        key: 'onListboxClick',
        value: function onListboxClick(e) {
            this.setSelectedOption(e.target);
            this.el.classList.remove('active');
            return this.getSelectBtn().focus();
        }
    }, {
        key: 'onListboxKeydown',
        value: function onListboxKeydown(e) {
            var _this3 = this;

            var key = e.keyIdentifier || e.key || '';

            if (e.which === 27 || key.match(/enter/i)) {
                this.el.classList.remove('active');
                return this.getSelectBtn().focus();
            }

            var items = this.getSelectOptionsListItems();

            if (!items.length) return;

            var selectOption = function selectOption(el) {
                e.preventDefault();
                e.stopPropagation();

                _this3.setSelectedOption(el);
            };

            if (key.match(/(home|end)/i)) return selectOption(key.match(/home/i) ? items[0] : items[items.length - 1]);

            var selected = this.getSelectOptionsList().querySelector('[aria-selected="true"][role=option]');

            var selectedIdx = items.indexOf(selected);

            if (key.match(/(up|down)/i)) {
                if (selectedIdx < 0) return;

                var next = key.match(/down/i) ? items[selectedIdx + 1] : items[selectedIdx - 1];

                if (next) return selectOption(next);
            }

            if (!key.match(/(\d|\w)/)) return;

            var jumpTo = items.slice(selectedIdx + 1).find(function (el) {
                return el.innerText.toLowerCase().charAt(0) === key.toLowerCase();
            });

            if (jumpTo) selectOption(jumpTo);
        }
    }, {
        key: 'onDocumentClick',
        value: function onDocumentClick(e) {
            var eventPath = e.path || [];

            // Build path array for other browsers
            if (!eventPath.length) {
                var node = e.target;

                while (node !== document.body) {
                    eventPath.push(node);
                    node = node.parentNode;
                }
            }

            if (eventPath.indexOf(this.el) < 0) this.el.classList.remove('active');
        }
    }, {
        key: 'setSelectedText',
        value: function setSelectedText(str) {
            this.getSelectBtn().querySelector('span').innerText = (str || (this.getSelectedOptionDefault() || {}).innerText || '').trim();
        }
    }, {
        key: 'getSelectedOptionDefault',
        value: function getSelectedOptionDefault() {
            return this.getSelectOptionsList().querySelector('[aria-selected="true"]') || this.getSelectOptionsListItems().filter(function (el) {
                return el.getAttribute('aria-disabled') !== 'true';
            })[0];
        }
    }, {
        key: 'setSelectedOption',
        value: function setSelectedOption(el) {
            if (!this.getSelectOptionsListItems().length) return;

            el = el || this.getSelectedOptionDefault();

            if (!el || el.getAttribute('aria-disabled') === 'true') return;

            if (el.getAttribute('aria-selected') !== 'true') this.getSelectOptionsListItems().filter(function (node) {
                return node.getAttribute('aria-selected');
            }).forEach(function (node) {
                return node.removeAttribute('aria-selected');
            });

            var option = this.getSelect().querySelector('option[data-id="' + el.id + '"]');

            if (!option) return;

            el.setAttribute('aria-selected', true);

            this.getSelectOptionsList().setAttribute('aria-activedescendant', el.id);

            this.getSelectOptionsList().scrollTop = el.offsetTop;
            this.setSelectedText();

            if (option === this.getSelectedOption()) return;

            this.getSelect().selectedIndex = Array.from(this.getSelect().querySelectorAll('option')).indexOf(option);

            this.getSelect().value = option.value;

            var evt = this.getBrowser('name') === 'ie' ? document.createEvent('Event') : new window.Event('change');

            if (this.getBrowser('name') === 'ie') evt.initEvent('change', true, true);

            this.getSelect().dispatchEvent(evt);
        }
    }, {
        key: 'getSelect',
        value: function getSelect() {
            return this.el.querySelector('select');
        }
    }, {
        key: 'getSelectBtn',
        value: function getSelectBtn() {
            return this.el.querySelector('.select-btn');
        }
    }, {
        key: 'getSelectContainer',
        value: function getSelectContainer() {
            return this.el.querySelector('.select-container');
        }
    }, {
        key: 'getSelectOptionsList',
        value: function getSelectOptionsList() {
            return this.el.querySelector('.select-options');
        }
    }, {
        key: 'getSelectedOption',
        value: function getSelectedOption() {
            return this.getSelect().selectedIndex < 0 ? null : this.getSelect().querySelectorAll('option')[this.getSelect().selectedIndex];
        }
    }, {
        key: 'getSelectOptions',
        value: function getSelectOptions() {
            return Array.from(this.el.querySelectorAll('select option'));
        }
    }, {
        key: 'getSelectOptionsListItems',
        value: function getSelectOptionsListItems() {
            return Array.from(this.getSelectOptionsList().querySelectorAll('[role="option"]')).filter(function (el) {
                return el.getAttribute('aria-disabled') !== 'true';
            });
        }
    }, {
        key: 'renderAriaLabel',
        value: function renderAriaLabel(el) {
            if (!el || !el.tagName.match(/label/i)) return el;

            el.id = el.id || this.getSelect().id + '-label';
            el.removeAttribute('for');
            el.classList.add('select-label');
            el.outerHTML = el.outerHTML.replace(/(<\/*)(label)(?=>)*/g, '$1span');

            return el;
        }
    }, {
        key: 'renderOptionsList',
        value: function renderOptionsList() {
            var _this4 = this;

            var optionsList = this.getSelectOptionsList();

            if (!optionsList) {
                optionsList = document.createElement('ul');
                optionsList.className = 'select-options';
                optionsList.id = this.getSelect().id + '-listbox';
                optionsList.setAttribute('role', 'listbox');
                optionsList.setAttribute('tabindex', -1);

                this.getSelectContainer().appendChild(optionsList);
            }

            this.getSelectOptions().forEach(function (option, idx) {
                if (idx === 0 && option.selected && option.disabled && !option.value) _this4.setSelectedText(option.innerHTML);

                var id = _this4.getSelect().id + '-option-' + idx;
                option.setAttribute('data-id', id);

                optionsList.innerHTML += '<li ' + (option.selected ? 'aria-selected="true" ' : '') + (option.disabled ? 'aria-disabled="true" ' : '') + 'value="' + option.value + '" role="option" id="' + id + '">' + option.innerHTML + '</li>\n                ';
            });

            if (!this.getSelectBtn().innerText.length) this.setSelectedOption();
        }
    }, {
        key: 'renderSelectBtn',
        value: function renderSelectBtn() {
            var selectBtn = this.getSelectBtn();

            if (!selectBtn) {
                selectBtn = document.createElement('a');
                selectBtn.innerHTML = '<span></span>';

                this.getSelectContainer().appendChild(selectBtn);
            }

            selectBtn.id = this.getSelect().id + '-btn';
            selectBtn.className = 'select-btn';

            selectBtn.setAttribute('role', 'button');
            selectBtn.setAttribute('tabindex', 0);
            selectBtn.setAttribute('aria-haspopup', 'listbox');
            selectBtn.setAttribute('aria-labelledby', selectBtn.id);
        }
    }, {
        key: 'render',
        value: function render() {
            var _this5 = this;

            if (!this.getSelect()) return;

            var select = this.getSelect();

            select.id = select.id || 'select-' + this.generateGUID();
            select.setAttribute('aria-hidden', true);
            select.setAttribute('tabindex', -1);

            if (!this.getSelectContainer()) {
                var selectContainer = document.createElement('div');
                selectContainer.classList.add('select-container');
                this.el.appendChild(selectContainer);
            }

            if (!this.getSelectBtn() || !this.getSelectBtn().id) this.renderSelectBtn();

            if (!this.getSelectOptionsList()) this.renderOptionsList();

            var labelledby = Array.from(this.el.querySelectorAll('label[for="' + this.getSelect().id + '"]')).map(function (el) {
                return _this5.renderAriaLabel(el);
            }).map(function (el) {
                return el.id;
            }).filter(function (str) {
                return str;
            }).concat((this.getSelect().getAttribute('aria-labelledby') || '').split(/\s+/).filter(function (str) {
                return str.length;
            }).map(function (id) {
                return _this5.el.querySelector('#' + id);
            }).filter(function (el) {
                return el;
            })).join(' ');

            if (labelledby && this.getSelectOptionsList()) this.getSelectOptionsList().setAttribute('aria-labelledby', labelledby);

            if (labelledby && this.getSelectBtn()) this.getSelectBtn().setAttribute('aria-labelledby', (labelledby + ' ' + this.getSelectBtn().getAttribute('aria-labelledby')).trim());
        }
    }]);

    return SelectComponent;
}(Component);

var StepperComponent = function (_Component) {
    _inherits(StepperComponent, _Component);

    _createClass(StepperComponent, null, [{
        key: 'selector',
        value: function selector() {
            return 'input[type=number][step]';
        }
    }]);

    function StepperComponent(opts) {
        _classCallCheck(this, StepperComponent);

        var _this = _possibleConstructorReturn(this, (StepperComponent.__proto__ || Object.getPrototypeOf(StepperComponent)).call(this, opts));

        _this.onButtonClick = _this.onButtonClick.bind(_this);
        _this.onButtonKeydown = _this.onButtonKeydown.bind(_this);

        _this.render();
        _this.listen();
        return _this;
    }

    _createClass(StepperComponent, [{
        key: 'listen',
        value: function listen() {
            var _this2 = this;

            this.el.addEventListener('change', this.onChange());

            this.getButtons().forEach(function (el) {
                el.addEventListener('click', _this2.onButtonClick);

                el.addEventListener('keydown', _this2.onButtonKeydown);
            });
        }
    }, {
        key: 'onChange',
        value: function onChange() {
            if (this.el.getAttribute('data-prev-value') === this.el.value) return;

            this.el.setAttribute('data-prev-value', this.el.value);

            var evt = this.getBrowser('name') === 'ie' ? document.createEvent('Event') : new window.Event('change');

            if (this.getBrowser('name') === 'ie') evt.initEvent('change', true, true);

            this.el.dispatchEvent(evt);
        }
    }, {
        key: 'onButtonClick',
        value: function onButtonClick(e) {
            var _this3 = this;

            e.preventDefault();
            e.stopPropagation();

            if (this.onButtonClickDebounced) clearTimeout(this.onButtonClickDebounced);

            var step = Number(this.el.step);
            var val = Number(this.el.value || '0');
            var isDecrement = e.target.classList.contains('stepper-btn-decrement');

            var newVal = isDecrement ? val - step : val + step;

            if (newVal < 0) newVal = 0;

            this.el.setAttribute('value', newVal);
            this.el.value = newVal;

            this.onButtonClickDebounced = setTimeout(function () {
                _this3.onChange();
            }, 600);
        }
    }, {
        key: 'onButtonKeydown',
        value: function onButtonKeydown(e) {
            if (e.keyCode === 32 || (e.keyIdentifier || e.key || '').match(/^(enter)$/i)) return this.onButtonClick(e);
        }
    }, {
        key: 'getButtons',
        value: function getButtons() {
            return Array.from(this.el.parentNode.querySelectorAll('.stepper-btn'));
        }
    }, {
        key: 'render',
        value: function render() {
            this.el.id = this.el.id || 'stepper-' + this.generateGUID();
            this.el.setAttribute('data-prev-value', this.el.value);
            this.el.pattern = '[0-9]*';

            if (this.el.parentNode.classList.contains('stepper')) return;

            if ('inputmode' in this.el) this.el.inputmode = 'numeric';

            var stepper = document.createElement('div');

            stepper.className = 'stepper';
            stepper.innerHTML = '\n            <button\n                class="stepper-btn-decrement stepper-btn"\n                id="' + this.el.id + '-decrement"\n                aria-label="Remove ' + this.el.step + '"\n                aria-labelledby="' + this.el.id + '-decrement ' + this.el.id + '"\n            ></button>\n            <button\n                class="stepper-btn-increment stepper-btn"\n                id="' + this.el.id + '-increment"\n                aria-label="Remove ' + this.el.step + '"\n                aria-labelledby="' + this.el.id + '-increment ' + this.el.id + '"\n            ></button>\n        ';

            this.el.parentNode.insertBefore(stepper, this.el);

            stepper.insertBefore(this.el, stepper.querySelector('#' + this.el.id + '-increment'));
        }
    }]);

    return StepperComponent;
}(Component);

var ShopComponent = function (_Component) {
    _inherits(ShopComponent, _Component);

    _createClass(ShopComponent, null, [{
        key: 'selector',
        value: function selector() {
            return '.shop';
        }
    }]);

    function ShopComponent(opts) {
        _classCallCheck(this, ShopComponent);

        var _this = _possibleConstructorReturn(this, (ShopComponent.__proto__ || Object.getPrototypeOf(ShopComponent)).call(this, opts));

        _this.render();
        _this.listen();
        return _this;
    }

    _createClass(ShopComponent, [{
        key: 'listen',
        value: function listen() {
            var _this2 = this;

            // Coupon Code UI/UX Fixes
            if (this.getCouponCodeInput()) this.getCouponCodeInput().addEventListener('keydown', this.onCouponCodeInputKeydown.bind(this));

            if (this.getApplyCouponBtn()) this.getApplyCouponBtn().addEventListener('click', this.onApplyCouponBtnClick.bind(this));

            if (this.getDonationInput() && this.getSingleAddToCartBtn()) this.getDonationInput().addEventListener('keyup', this.onDonationInputKeyUp.bind(this)

            // Always render on WooCommerce DOM changes
            );if ('MutationObserver' in window) new window.MutationObserver(function (mutations) {
                return _this2.render();
            }).observe(this.el, {
                childList: true,
                subtree: true
            });
        }
    }, {
        key: 'onCartUpdate',
        value: function onCartUpdate(force) {
            if (!this.getCartUpdateBtn()) return;

            if (force) this.getCartUpdateBtn().disabled = false;

            if (!this.getCartUpdateBtn().disabled) return this.getCartUpdateBtn().click();
        }
    }, {
        key: 'onCouponCodeInputKeydown',
        value: function onCouponCodeInputKeydown(e) {
            if (!(e.keyIdentifier || e.key || '').match(/^(enter)$/i)) return;

            e.preventDefault();
            e.stopPropagation();

            if (!e.target.classList.contains('placeholder-shown')) this.getApplyCouponBtn().click();
        }
    }, {
        key: 'onApplyCouponBtnClick',
        value: function onApplyCouponBtnClick(e) {
            if (this.getCouponCodeInput() && !this.getCouponCodeInput().classList.contains('placeholder-shown')) return;

            e.preventDefault();
            e.stopPropagation();
        }
    }, {
        key: 'onDonationInputKeyUp',
        value: function onDonationInputKeyUp(e) {
            if (!e.target || !this.getSingleAddToCartBtn()) return;

            var val = Number(e.target.value);
            var min = Number(e.target.parentElement.getAttribute('data-min-price'));

            if (val >= min && this.getSingleAddToCartBtn().disabled) {
                this.getSingleAddToCartBtn().disabled = false;
                this.getSingleAddToCartBtn().classList.remove('disabled');
            }

            if ((!val || min > val) && !this.getSingleAddToCartBtn().disabled) {
                this.getSingleAddToCartBtn().disabled = true;
                this.getSingleAddToCartBtn().classList.add('disabled');
            }
        }
    }, {
        key: 'getCouponCodeInput',
        value: function getCouponCodeInput() {
            return this.el.querySelector('input#coupon_code');
        }
    }, {
        key: 'getApplyCouponBtn',
        value: function getApplyCouponBtn() {
            return this.el.querySelector('button[name="apply_coupon"]');
        }
    }, {
        key: 'getCartUpdateBtn',
        value: function getCartUpdateBtn() {
            return this.el.querySelector('button[name="update_cart"]');
        }
    }, {
        key: 'getDonationInput',
        value: function getDonationInput() {
            return this.el.querySelector('input#nyp');
        }
    }, {
        key: 'getSingleAddToCartBtn',
        value: function getSingleAddToCartBtn() {
            return this.el.querySelector('button.single_add_to_cart_button');
        }
    }, {
        key: 'getSelects',
        value: function getSelects() {
            return Array.from(this.el.querySelectorAll('select'));
        }
    }, {
        key: 'getSteppers',
        value: function getSteppers() {
            return Array.from(this.el.querySelectorAll(StepperComponent.selector()));
        }
    }, {
        key: 'getValidatedFields',
        value: function getValidatedFields() {
            var selectors = '.validate-required .input-text:not([data-rendered="true"])';

            selectors += ',.validate-required select:not([data-rendered="true"])';

            return Array.from(this.el.querySelectorAll(selectors));
        }
    }, {
        key: 'renderPlaceholderShownPolyfill',
        value: function renderPlaceholderShownPolyfill() {
            Array.from(this.el.querySelectorAll('[placeholder]:not([data-placeholder-shown-polyfill])')).forEach(function (el) {
                el.setAttribute('data-placeholder-shown-polyfill', true);

                var setPlaceholderShownClass = function setPlaceholderShownClass() {
                    return el.classList[el.value ? 'remove' : 'add']('placeholder-shown');
                };

                el.addEventListener('change', setPlaceholderShownClass);
                el.addEventListener('keyup', setPlaceholderShownClass);

                setPlaceholderShownClass();
            });
        }
    }, {
        key: 'renderSteppers',
        value: function renderSteppers() {
            var _this3 = this;

            var stepperListeners = function stepperListeners() {
                return _this3.getSteppers().filter(function (el) {
                    return el.getAttribute('data-stepper-listener') !== 'true';
                }).forEach(function (el) {
                    el.getAttribute('data-stepper-listener', true);

                    el.addEventListener('change', function () {
                        if (el !== document.activeElement) _this3.onCartUpdate(true);
                    });

                    el.addEventListener('blur', function () {
                        if (el.getAttribute('data-prev-value') !== el.value) _this3.onCartUpdate();
                    });
                });
            };

            stepperListeners();

            this.getSteppers().filter(function (el) {
                return el.getAttribute('data-rendered') !== 'true';
            }).forEach(function (el) {
                return Promise.resolve(new StepperComponent({
                    el: el,
                    props: el.dataset
                })).then(function () {
                    return stepperListeners();
                });
            });
        }
    }, {
        key: 'renderTouchSelects',
        value: function renderTouchSelects() {
            if (!this.getFeature('touch')) return;

            this.getSelects().filter(function (el) {
                return el.getAttribute('tabindex') || el.getAttribute('aria-hidden');
            }).forEach(function (el) {
                el.removeAttribute('tabindex');
                el.removeAttribute('aria-hidden');
            });
        }
    }, {
        key: 'renderValidatedFields',
        value: function renderValidatedFields() {
            var _this4 = this;

            // Real-time validation fixes
            this.getValidatedFields().forEach(function (el) {
                el.setAttribute('data-rendered', true);
                el.setAttribute('required', true);

                if (el.value) el.classList.add('dirty');

                if (el.tagName.match(/input/i) && !el.placeholder) el.placeholder = ' ';

                if (el.type === 'email') el.pattern = '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';

                if (el.type === 'tel') el.pattern = '^\\+*[0-9\\-\\s\\.()]+$';

                var isSelect = el.tagName.match(/select/i);

                if (!isSelect && el.parentElement.classList.contains('woocommerce-input-wrapper')) {
                    var icon = document.createElement('span');

                    icon.className = 'woocommerce-input-wrapper-icon';
                    icon.innerHTML = '<i class="fas fa-check green"></i>';
                    icon.innerHTML += '<i class="fas fa-exclamation pink"></i>';

                    el.parentElement.appendChild(icon);
                }

                var setFieldDirty = function setFieldDirty() {
                    return !el.classList.contains('dirty') ? el.classList.add('dirty') : null;
                };

                var isSelectNoTouch = el.tagName.match(/select/i) && !_this4.getFeature('touch');

                if (!isSelectNoTouch) el.addEventListener('blur', setFieldDirty);

                var selectComboBox = isSelectNoTouch && el.nextElementSibling ? el.nextElementSibling.querySelector('[role="combobox"]') : null;

                if (selectComboBox) selectComboBox.addEventListener('blur', setFieldDirty);

                if (_this4.el.querySelector('form')) _this4.el.querySelector('form').addEventListener('submit', setFieldDirty);

                var isError = _this4.el.querySelector('.woocommerce-error') && _this4.el.querySelector('.woocommerce-error').innerText.match(/required field/);

                if (isError) setFieldDirty();
            });
        }
    }, {
        key: 'render',
        value: function render() {
            this.renderSteppers();
            this.renderValidatedFields();
            this.renderPlaceholderShownPolyfill();
            this.renderTouchSelects();
        }
    }]);

    return ShopComponent;
}(Component);

var TextRotateVerticalComponent = function (_Component) {
    _inherits(TextRotateVerticalComponent, _Component);

    _createClass(TextRotateVerticalComponent, null, [{
        key: 'selector',
        value: function selector() {
            return '.text-rotate-vertical';
        }
    }]);

    function TextRotateVerticalComponent(opts) {
        _classCallCheck(this, TextRotateVerticalComponent);

        var _this = _possibleConstructorReturn(this, (TextRotateVerticalComponent.__proto__ || Object.getPrototypeOf(TextRotateVerticalComponent)).call(this, opts));

        _this.render();
        _this.listen();
        return _this;
    }

    _createClass(TextRotateVerticalComponent, [{
        key: 'listen',
        value: function listen() {
            var _this2 = this;

            this.on('resize', function () {
                return _this2.render();
            });
        }
    }, {
        key: 'getParentPadding',
        value: function getParentPadding(direction) {
            if (!direction || !this.el.parentNode) return 0;

            return Number(window.getComputedStyle(this.el.parentNode)['padding' + direction].replace(/[a-z]/ig, '')) || 0;
        }
    }, {
        key: 'render',
        value: function render() {
            if (!this.el.parentNode) return;

            var minHeight = this.el.clientWidth + 'px';

            if (this.el.parentNode.style.minHeight !== minHeight) this.el.parentNode.style.minHeight = minHeight;

            var minWidth = this.el.clientHeight + this.getParentPadding('Left') + this.getParentPadding('Right') + 'px';

            if (this.el.parentNode.style.minWidth !== minWidth) this.el.parentNode.style.minWidth = minWidth;
        }
    }]);

    return TextRotateVerticalComponent;
}(Component);

var BookSearchComponent = function (_Component) {
    _inherits(BookSearchComponent, _Component);

    _createClass(BookSearchComponent, null, [{
        key: 'selector',
        value: function selector() {
            return '.basic-book-search';
        }
    }]);

    function BookSearchComponent(opts) {
        _classCallCheck(this, BookSearchComponent);

        var _this = _possibleConstructorReturn(this, (BookSearchComponent.__proto__ || Object.getPrototypeOf(BookSearchComponent)).call(this, opts));

        _this.listen();
        return _this;
    }

    _createClass(BookSearchComponent, [{
        key: 'listen',
        value: function listen() {
            var _this2 = this;

            if (!this.getInputSearch() || !this.getButtonSearch()) return;

            this.getButtonSearch().addEventListener('focus', function () {
                return _this2.onFocus();
            });

            this.getInputSearch().addEventListener('focus', function () {
                return _this2.onFocus();
            });

            this.getButtonSearch().addEventListener('blur', function () {
                return _this2.onBlur();
            });

            this.getInputSearch().addEventListener('blur', function () {
                return _this2.onBlur();
            });

            this.el.addEventListener('mouseout', function () {
                return setTimeout(function () {
                    return _this2.onBlur();
                }, 200);
            });
        }
    }, {
        key: 'onBlur',
        value: function onBlur() {
            if (this.isActive() && document.activeElement !== this.getButtonSearch() && document.activeElement !== this.getInputSearch() && !this.el.querySelector('form:hover')) this.props.formActive = false;
        }
    }, {
        key: 'onFocus',
        value: function onFocus() {
            if (!this.isActive() && (document.activeElement === this.getInputSearch() || document.activeElement === this.getButtonSearch())) this.props.formActive = true;
        }
    }, {
        key: 'getInputSearch',
        value: function getInputSearch() {
            return this.el.querySelector('input[type="search"]');
        }
    }, {
        key: 'getButtonSearch',
        value: function getButtonSearch() {
            return this.el.querySelector('button[type="submit"]');
        }
    }, {
        key: 'isActive',
        value: function isActive() {
            return this.props.formActive === 'true';
        }
    }]);

    return BookSearchComponent;
}(Component);

var AnnouncementBannerComponent = function (_Component) {
    _inherits(AnnouncementBannerComponent, _Component);

    _createClass(AnnouncementBannerComponent, null, [{
        key: 'selector',
        value: function selector() {
            return '.announcement-banner';
        }
    }]);

    function AnnouncementBannerComponent(opts) {
        _classCallCheck(this, AnnouncementBannerComponent);

        var _this = _possibleConstructorReturn(this, (AnnouncementBannerComponent.__proto__ || Object.getPrototypeOf(AnnouncementBannerComponent)).call(this, opts));

        _this.listen();
        return _this;
    }

    _createClass(AnnouncementBannerComponent, [{
        key: 'listen',
        value: function listen() {
            var _this2 = this;

            if (this.getDismissBtn()) this.getDismissBtn().addEventListener('click', function () {
                return _this2.setDimissCookie();
            });
        }
    }, {
        key: 'getDismissBtn',
        value: function getDismissBtn() {
            return this.el.querySelector('.announcement-banner-dismiss-btn');
        }
    }, {
        key: 'setDimissCookie',
        value: function setDimissCookie() {
            var cookie = this.el.id + '=dismissed; path=/;';

            var expires = new Date(new Date().getTime() + 365 * 86400000);

            cookie += 'expires=' + expires.toUTCString() + ';';

            document.cookie = cookie;

            this.el.style.display = 'none';
        }
    }]);

    return AnnouncementBannerComponent;
}(Component);

var Components = {
    GlobalHeaderComponent: GlobalHeaderComponent,
    HeroCarouselComponent: HeroCarouselComponent,
    HashAnchorComponent: HashAnchorComponent,
    FormValidatorComponent: FormValidatorComponent,
    MailchimpFormComponent: MailchimpFormComponent,
    CalendarComponent: CalendarComponent,
    FeaturedCarouselComponent: FeaturedCarouselComponent,
    SelectComponent: SelectComponent,
    PhotoGalleryComponent: PhotoGalleryComponent,
    QueryFilterComponent: QueryFilterComponent,
    StepperComponent: StepperComponent,
    ShopComponent: ShopComponent,
    TextRotateVerticalComponent: TextRotateVerticalComponent,
    BookSearchComponent: BookSearchComponent,
    ModalComponent: ModalComponent,
    MailchimpPopupComponent: MailchimpPopupComponent,
    AnnouncementBannerComponent: AnnouncementBannerComponent
};

var Scroller = function (_Obj) {
    _inherits(Scroller, _Obj);

    function Scroller() {
        var _ret, _ret2;

        var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : { el: window };

        _classCallCheck(this, Scroller);

        var _this = _possibleConstructorReturn(this, (Scroller.__proto__ || Object.getPrototypeOf(Scroller)).call(this));

        _this.el = opts.el;
        _this.ticking = false;
        _this.state = new Baobab({
            scrollX: 0,
            scrollY: 0,
            directionY: null,
            directionX: null
        });

        if (typeof _this.el === 'string' && !document.querySelector(_this.el)) return _ret = _this.observe(_this.el), _possibleConstructorReturn(_this, _ret);else if (typeof _this.el === 'string') _this.el = document.querySelector(_this.el);

        return _ret2 = _this.listen(), _possibleConstructorReturn(_this, _ret2);
    }

    _createClass(Scroller, [{
        key: 'observe',
        value: function observe() {
            var _this2 = this;

            var observer = new window.MutationObserver(function () {
                if (typeof _this2.el === 'string' && document.querySelector(_this2.el)) {
                    _this2.el = document.querySelector(_this2.el);
                    observer.disconnect();
                    _this2.listen();
                }
            });

            observer.observe(document.documentElement, {
                childList: true,
                subtree: true
            });

            return this;
        }
    }, {
        key: 'listen',
        value: function listen() {
            var _this3 = this;

            this.el.addEventListener('scroll', function () {
                return _this3.onScroll();
            });

            if ('ontouchstart' in document.documentElement) this.el.addEventListener('touchstart', function () {
                return _this3.onScroll();
            });

            this.onScroll();
            return this;
        }
    }, {
        key: 'onScroll',
        value: function onScroll() {
            var _this4 = this;

            if (this.ticking) return;

            window.requestAnimationFrame(function () {
                _this4.ticking = false;
            });

            var scrollX = this.getScroll('scrollX');
            var scrollY = this.getScroll('scrollY');

            var getScrolledDirection = function getScrolledDirection(axis) {
                var scrollAxis = 'scroll' + axis;
                var directionAxis = 'direction' + axis;

                var scrollDiff = _this4.state.get(scrollAxis) - _this4.getScroll(scrollAxis);

                if (scrollDiff < 0) scrollDiff = scrollDiff * -1;

                var prevScroll = axis === 'Y' ? scrollY : scrollX;

                if (scrollDiff < 0.5) return _this4.state.get(directionAxis) && prevScroll !== _this4.state.get(scrollAxis) ? _this4.state.get(directionAxis) : null;

                return prevScroll < _this4.state.get(scrollAxis) ? axis === 'Y' ? 'up' : 'left' : axis === 'Y' ? 'down' : 'right';
            };

            this.state.set({
                scrollX: scrollX,
                scrollY: scrollY,
                directionX: getScrolledDirection('X'),
                directionY: getScrolledDirection('Y')
            });

            this.ticking = true;
        }
    }, {
        key: 'getScroll',
        value: function getScroll(dir) {
            // Scroll{Y,X} only on window
            if (this.el[dir]) return this.el[dir];

            // Scroll{Y,X} fallback, only on window
            var pageOffset = dir === 'scrollY' ? this.el.pageYOffset : this.el.pageXOffset;

            if (pageOffset) return pageOffset;

            // Update dir to target overflow elements and/or legacy browsers
            dir = dir === 'scrollY' ? 'scrollTop' : 'scrollLeft';

            if (this.el[dir]) return this.el[dir];

            // Legacy browsers only
            return (document.compatMode || '') === 'CSS1Compat' ? document.documentElement[dir] : document.body[dir];
        }
    }, {
        key: 'scrollTo',
        value: function scrollTo() {
            var _this5 = this;

            var coordinates = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : { x: 0, y: 0 };
            var time = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.75;

            coordinates.x = coordinates.x || 0;
            coordinates.y = coordinates.y || 0;

            return new Promise(function (resolve) {
                if (!_this5.el) return resolve

                // Fallback scroll
                ();if (!window.TweenLite || !window.ScrollToPlugin) {
                    if (_this5.el === window) window.scrollTo(coordinates.x, coordinates.y);else {
                        _this5.el.scrollLeft = coordinates.x;
                        _this5.el.scrollTop = coordinates.y;
                    }

                    return resolve();
                }

                // Animated scroll flag
                _this5.scrollingTo = true;

                // Animated scroll
                window.TweenLite.to(_this5.el, time, {
                    scrollTo: coordinates,
                    ease: window.Power3.easeOut,
                    onComplete: function onComplete() {
                        _this5.scrollingTo = false;
                        _this5.onScroll();
                        resolve();
                        _this5.emit('scrollingTo:complete');
                    }
                });
            });
        }
    }]);

    return Scroller;
}(Obj);

//* ---------------------------------------

//* ---------------------------------------
//  Create RootComponent

var RootComponent = new Component({
    el: document.body,
    name: 'RootComponent',
    props: document.body.dataset,
    Scroller: new Scroller()
});

//* ---------------------------------------
//  Detect Features like Modernizr

RootComponent.addFeatureTest({
    csspointerevents: function csspointerevents() {
        return RootComponent.detectCSSProp('pointer-events');
    },
    csstouchaction: function csstouchaction() {
        return RootComponent.detectCSSProp('touch-action');
    }
}).addFeaturesToClassName

//* ---------------------------------------
//  RootComponent on Scroll Toggling

();RootComponent.on('scroll:disable', function () {
    return document.documentElement.classList.add('overflow-hidden');
});

RootComponent.on('scroll:enable', function () {
    return document.documentElement.classList.remove('overflow-hidden');
}

//* ---------------------------------------
//  RootComponent on Resize

);RootComponent.on('resize', function () {
    var scrollOffset = ((RootComponent.el.querySelector('#wpadminbar') || {}).clientHeight || 0) + ((RootComponent.el.querySelector('#global-header-editorial-navbar-top') || {}).clientHeight || 0);

    if (RootComponent.props.scrollOffset !== scrollOffset) RootComponent.props.scrollOffset = scrollOffset;

    return window.objectFitPolyfill();
}).emit('resize'

//* ---------------------------------------
//  RootComponent on Render

);RootComponent.on('render', function (e) {
    return Object.keys(Components).filter(function (key) {
        return 'selector' in Components[key] && typeof Components[key] === 'function' && RootComponent.el.querySelector(Components[key].selector());
    }).forEach(function (key) {
        return Array.from(document.querySelectorAll(Components[key].selector() + ':not([data-rendered])')).filter(function (el) {
            return !el.getAttribute('data-rendered');
        }).forEach(function (el) {
            return Promise.resolve(new Components[key]({
                el: el,
                props: el.dataset
            })).then(function () {
                return window.objectFitPolyfill();
            });
        });
    });
}).emit('render');

export { RootComponent };
